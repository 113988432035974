import React from "react";
import "./ProgressBar.css";

const CircularProgressBar = ({ value, total, fee,paid }) => {
  // value is amount need to be paid(due)
  const radius = 40;
  const circumference = Math.PI * radius;
  const progress = (value/ total) * circumference;

  // console.log("This is hte value", value, total, fee,paid);
  return (
    <div className="half-circular-progress-bar">
      <svg viewBox="0 0 100 50" className="half-circular-progress-bar__svg">
        <path
          d="M 10 50 A 40 40 0 0 1 90 50"
          stroke="#E6E6E6"
          strokeWidth="10"
          fill="none"
          width={150}
          height={150}
        />
        <path
          d="M 10 50 A 40 40 0 0 1 90 50"
          stroke="#4285F4"
          strokeWidth="10"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={String(circumference - progress)}
        />
        <text
          x="50"
          y="30"
          textAnchor="middle"
          className="semicircle__svg--text"
          fill="#666"
        >{`AMOUNT PAID`}</text>
        <text
          x="50"
          y="46"
          textAnchor="middle"
          fontSize="16px"
          fill="#000"
          className="semicircle__svg--amount"
        >
          {`${(paid).toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
          })}`}
        </text>
      </svg>
    </div>
  );
};
export default CircularProgressBar;
