import React from 'react'
import { CustomDropdownWithCustomStyle } from "../../StudentDirectoryModule/Components/CustomDropdownWithCustomStyle";
export const StaffProfessionalInfo = ({ data,setdata,findmandatorychecks }) => {
  const handledatachange = (field, value) => {
    if (field==="emergency_contact" || field==="years_of_experience") {
      if(/^\d*\.?\d*$/.test(value)){}
        else return;
      
    }

      const updatedData = { ...data, [field]: value };
      setdata(updatedData);
    
 
    findmandatorychecks(updatedData);
    // console.log(await findmandatorychecks())
  };
  return (
    <div >
      <div>
        <div className="detailsHeader">Professional Info</div>
        <div className="detailsHeaderspan">Enter the details to proceed </div>
        <div className="StudentInfoEditSilderdivbody">
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Years of Experience
               
              </label>
              <input type="text" className="inputbox" placeholder="123456789"    value={data.years_of_experience} onChange={(e)=>{handledatachange("years_of_experience",e.target.value)}}
/>
              {/* <CustomDropdownWithCustomStyle
                defaultText={data.years_of_experience?data.years_of_experience:"Select a Option"}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={["male", "female"]}
                width={"12.9rem"}
                dropdownName={"years_of_experience"}
                height="1.1rem"
              /> */}
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Employment Type
                
              </label>
              <CustomDropdownWithCustomStyle
               defaultText={data.contract_type?data.contract_type:"Select a option"}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={["Full Time","Part Time","Contract","Internship"]}
                width={"12.9rem"}
                dropdownName={"contract_type"}
                scrollenable={true}
                height="1.1rem"
              />
            </div>
          </div>

          <div className="Details">
            <label style={{ display: "flex" }}>
              Highest Qualification
              <div style={{ color: "rgba(153, 160, 174, 1)" }}>(Optional)</div>
            </label>
            <CustomDropdownWithCustomStyle
               defaultText={data.highest_education?data.highest_education:"Select a option"}
               // icon={<FilterIcon />}
               handledatachange={handledatachange}
              dropdownwidth="28.5rem"
              items={["B.Tech", "M.Tech", "Bachelor of Education (B.Ed)", "Master of Education (M.Ed)"]}
              width={"28.5rem"}
              dropdownName={"highest_education"}
              scrollenable={true}
              height="1.1rem"
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Select Subject expertise
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <CustomDropdownWithCustomStyle
           defaultText={data.primary_subject?data.primary_subject:"Select a option"}
           // icon={<FilterIcon />}
           handledatachange={handledatachange}
              dropdownwidth="28.5rem"
              items={["Mathematics", "Science", "Literature", "History", "Geography", "Art", "Music", "Computer Science", "Physical Education", "Philosophy", "Economics", "Psychology", "Sociology", "Foreign Languages", "Environmental Studies"]}
              width={"28.5rem"}
              dropdownName={"primary_subject"}
              scrollenable={true}
              height="1.1rem"
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Background Verification
             
            </label>
            <CustomDropdownWithCustomStyle
             defaultText={data.background_check?data.background_check:"Active"}
             // icon={<FilterIcon />}
             handledatachange={handledatachange}
              items={["Active","Not Active"]}
              width={"28.5rem"}
              dropdownwidth="28.5rem"
              dropdownName={"background_check"}
              scrollenable={true}
              height="1.1rem"
            />
          </div>

       
        </div>
      </div>
    </div>
  );
}
