import React from "react";

import { CustomDropdownWithCustomStyle } from "../../StudentDirectoryModule/Components/CustomDropdownWithCustomStyle";
// import DatePicker from "react-datepicker";
// import { DatePickerWrapper } from "../../FeeManagementModule/FeeStructureModule/components/AddFeeShedule";

export const StaffPersonalInfo = ({ data,setdata,findmandatorychecks }) => {
  const handledatachange = (field, value) => {
    if (field==="emergency_contact") {
      if(/^\d*\.?\d*$/.test(value)){}
        else return;
      
    }

      const updatedData = { ...data, [field]: value };
      setdata(updatedData);
    
 
    findmandatorychecks(updatedData);
    // console.log(await findmandatorychecks())
  };
  return (
    <div>
      <div>
        <div className="detailsHeader">Personal Info</div>
        <div className="detailsHeaderspan">Enter the details to proceed </div>
        <div className="StudentInfoEditSilderdivbody">
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Gender
               
              </label>
              <CustomDropdownWithCustomStyle
                defaultText={data.gender?data.gender:"select Gender"}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                dropdownName={"gender"}
                items={["male", "female"]}
                width={"12.9rem"}
                height="1.1rem"
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Blood Group
              
              </label>
              <CustomDropdownWithCustomStyle
                defaultText={data.blood_group?data.blood_group:"Select Blood Group"}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                width={"12.9rem"}
                dropdownName={"blood_group"}
                scrollenable={true}
                height="1.1rem"
              />
            </div>
          </div>

          <div className="Details">
            <label style={{ display: "flex" }}>
              Address
              <div style={{ color: "rgba(153, 160, 174, 1)" }}>(Optional)</div>
            </label>
            {/* <input type="date"  name="" id="" placeholder="Select a Date" /> */}
            <input
              type="text"
              className="inputbox"
              style={{ width: "28.5rem" }}
              placeholder="123456789"
              value={data.address} onChange={(e)=>{handledatachange("address",e.target.value)}}
            />
          </div>
          <div className="relationspanitem">
          <div className="line" style={{ width: "7rem" }}></div>
          <div className="relationspan">EMERGENCY CONTACT DETAILS</div>
          <div className="line"></div>
        </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Name of Emergency Contact
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "28.5rem" }}
              placeholder="123456789"
              value={data.name_of_emergency_contact} onChange={(e)=>{handledatachange("name_of_emergency_contact",e.target.value)}}
            />
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Phone Number
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <input type="text" className="inputbox" placeholder="123456789"    value={data.emergency_contact} onChange={(e)=>{handledatachange("emergency_contact",e.target.value)}}
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Relation
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <CustomDropdownWithCustomStyle
                defaultText={data.relation_of_emergency_contact?data.relation_of_emergency_contact:"Select a option"}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={[
                  "Parent",
                  "Child",
                  "Sibling",
                  "Grandparent",
                  "Grandchild",
                  "Aunt",
                  "Uncle",
                  "Nephew",
                  "Niece",
                  "Cousin",
                  "Great-Grandparent",
                  "Great-Grandchild",
                ]}
                width={"12.9rem"}
                dropdownName={"relation_of_emergency_contact"}
           
                height="1.1rem"
                // <input type="text" className="inputbox" placeholder="123456789"  value={data.relation_of_emergency_contact} onChange={(e)=>{handledatachange("relation_of_emergency_contact",e.target.value)}} 
                />
              
            </div>
          </div>

         
        </div>
      </div>
    </div>
  );
};


// <DatePickerWrapper>
//   <DatePicker
//     //   onChange={(date) => {
//     //     handledateselect(index, date);
//     //   }}
//     //   selected={
//     //     selecteddatelist[index] === "Select a date"
//     //       ? new Date()
//     //       : selecteddatelist[index]
//     //   }
//     inline
//   />
//   <div className="button-group">
//     <button
//       className="cancel-button"
//       // onClick={() => {
//       //   handlecalendaropenandclose(index);
//       //   handlecancelcalendar(index);
//       // }}
//     >
//       Cancel
//     </button>
//     <button
//       className="apply-button"
//       // onClick={() => {
//       //   handlecalendaropenandclose(index);
//       // }}
//     >
//       Apply
//     </button>
//   </div>
// </DatePickerWrapper>;