import React, { useEffect, useState } from "react";
import "./AssignandMoveClassPopup.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { CrossMark } from "../../../assets/svgs";
import { NameAvatar } from "../../FeeManagementModule/FeeCollectionModule/components/NameAvatar";
// import { SlArrowDown } from "react-icons/sl";
import { Classsectiondetailsdropdown } from "./Classsectiondetailsdropdown.js";
import { assignandmoveclass } from "../../../api/api.js";

export const AssignandMoveClassPopup = ({
  headertext,
  dropdownlabeltext,
  data,
  setpopup,
  task,
  refreshdata,
  bulk
}) => {
  console.log(data);
  //eslint-disable-next-line
  const [loading, setloading] = useState(true);
  // const []
  const [bulkclasschange, setbulkclasschange] = useState(null);
  const [editdata, editsetdata] = useState([]);
  useEffect(() => {
    try {
      setloading(true);
      editsetdata(data);
      // setenrollstatus(data.enrolment_status)
    } catch (error) {
      setloading(false);
    }
  }, [data]);

  const handledatachange1 = async (key, value) => {
    console.log(key, value);
    if (bulk) {
      console.log(value);
      setbulkclasschange(value);
      return;
    }
    let temp = { ...editdata };

    // let temp = { ...editdata };
    temp[key] = value;

    console.log(temp);
    await editsetdata(temp);
  };
  const handlesavechanges = async () => {
    console.log(editdata);
    console.log(bulkclasschange);
    let tempdata1 = [];
    if (bulk) {
      for (let i = 0; i < data.length; i++) {
        tempdata1.push({
          user_id: data[i].id,
          // current_class_section_mapping_id: data[i].class_section_mapping_id,
          new_class_section_mapping_id: bulkclasschange,
          ...(task === "move"
            ? {
                current_class_section_mapping_id:
                  data[i].class_section_mapping_id,
              }
            : {}),
        });
      }
    } else {
      tempdata1.push({
        user_id: data.id,
        // current_class_section_mapping_id: data.class_section_mapping_id,
        new_class_section_mapping_id: editdata.class_section_mapping_id,
        ...(task === "move"
          ? {
              current_class_section_mapping_id: data.class_section_mapping_id,
            }
          : {}),
      });
    }

    let tempdata = {
      student_details: tempdata1,
    };
    console.log(tempdata);

    await assignandmoveclass(tempdata);

    // navigate("/student/transactions//general");
    setpopup(false);
    refreshdata();
  };
  return (
    <div className="AssignandMoveClassPopup">
      <div className="AssignandMoveClassPopupBody">
        <div className="AssignandMoveClassPopupBodyHeader">
          <div className="ItemSeparator">
            <div className="AssignandMoveClassPopupBodyHeadercircle">
              <FaArrowRightLong />
            </div>
            {headertext}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setpopup(false);
            }}
          >
            <CrossMark />
          </div>
        </div>
        <div className="AssignandMoveClassPopupBodyBody">
          {!bulk && (
            <div className="ItemSeparator">
              <NameAvatar
                name={data.full_name}
                image={`https://api.dicebear.com/8.x/initials/svg?seed=${data.full_name}`}
                id={null}
              />
              <div className="StudentId">#null</div>
            </div>
          )}
          <div className="Dropdownforselection">
            <div className="dropdownlabel">
              {dropdownlabeltext}
              <div class="mandatory" style={{ color: "rgb(251, 55, 72)" }}>
                {" "}
                *
              </div>
            </div>
            <Classsectiondetailsdropdown
              defaultText={
                bulk || editdata.class === null
                  ? "Select Class-Section"
                  : `${editdata.class}-${editdata.section}`
              }
              handledatachange={handledatachange1}
              // field={}
              dropdownname="select class"
              width={"90%"}
            />
          </div>
          <div className="popup-footer">
            <button
              className="cancel-button"
              // onClick={() => setpopup(false)}
              onClick={() => {
                setpopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className="save-button"
              // onClick={() => setpopup(false)}
              onClick={() => {
                handlesavechanges();
                // setpopup(false);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
