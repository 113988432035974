import React from "react";
import "./SearchBar.css";
import { CiSearch } from "react-icons/ci";
// import { TbSortDescending } from 'react-icons/tb'
// import { BsFilter } from 'react-icons/bs'

export const SearchBar = ({ placeholder="Search for a Student",key1="full_name", data, handlesearchdata,width='' ,height=''}) => {
  console.log(key1);
  const filterByKeyValue = (key, value) => {
    // console.log(key, value);
    if (data.length && (typeof data !=='object' && typeof data[0] !== 'object')) {
      const temp = data.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
      handlesearchdata(1,temp);
      return;
    }
    const temp = data.filter((item) => {
      return item[key] && item[key].toLowerCase().includes(value.toLowerCase());
    });
    // console.log(temp)
    handlesearchdata(1,temp);
  };
  return (
    <div className="search-filter-sort">
      <div className="search-bar" style={{width:width,height:height}}>
        <div className="search-icon">
          <CiSearch size={19} />
        </div>
        <input
          type="text"
          style={{ color: "#99A0AE" }}
          placeholder={placeholder}
          onChange={(e) => filterByKeyValue(key1, e.target.value)}
        />
        {/* <div className="shortcut-icon">⌘ 1</div> */}
      </div>
    </div>
  );
};
