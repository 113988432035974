import React, { useEffect, useState } from "react";
import "./StudentGeneralInfo.css";
import { FiEdit3 } from "react-icons/fi";
import StatusButton from "../FeeManagementModule/FeeStructureModule/components/StatusButton";
import { StudentInfoEditSilder } from "./Components/StudentInfoEditSilder";
import { PersonalInfoEditSilder } from "./Components/PersonalInfoEditSlider";
// import { GuardianInfoEditSlider } from "./Components/GuardianInfoEditSlider";
import { useLocation } from "react-router-dom";
import { getStudentDetailsStudentDirectory,getStudentParentalInfo } from "../../api/api";
import Skeleton from "react-loading-skeleton";
import { ParentalInfoEditSlider } from "./Components/ParentalInfoEditSlider";
export const findnull = (value) => {
  if (value === null || value === undefined || value === "") {
    return "-";
  }
  return value;
};
export const StudentGeneralInfo = () => {
  const location = useLocation();
  const userid = location.pathname.split("/")[3];
  const [studentinfoedit,setstudentinfoedit]=useState(false);
  const [personalinfoedit, setpersonalinfoedit] = useState(false);
  const [parentalinfoedit, setparentalinfoedit] = useState(false);
  const [data,setdata]=useState([]);
  const [loading,setloading]=useState(true);
  const [fatherinfo,setfatherinfo] =useState([]);
  const [motherinfo,setmotherrinfo] =useState([]);
  const [guardianinfo,setguardianinfo] =useState([]);
  const [parentalsliderdata,setparentalsliderdata] = useState([]);
  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        setloading(true);
        const data = await getStudentDetailsStudentDirectory(userid);
        setdata(data);
        const d1=await getStudentParentalInfo(userid,"");
        console.log(d1);
       if(d1.father_info){
        setfatherinfo(d1.father_info);

       }
       if(d1.mother_info){
        setmotherrinfo(d1.mother_info);

       }
       if(d1.guardian_info.length){
        setguardianinfo(d1.guardian_info[0])
       }
        // for(let i=0;i<d1.length;i++){
        //   if(d1[i].relationship==="Father"){
        //     setfatherinfo(d1[i]);
        //     console.log(d1[i]);
        //   }
        //   else if(d1[i].relationship==="Mother"){
        //     setmotherrinfo(d1[i]);
        //   }
        //   else{
        //     setguardianinfo(d1[i]);
        //   }
        // }
       
      
      } finally {
        setloading(false);
      }
    };
    fetchStudentDetails();
    //eslint-disable-next-line
  }, [studentinfoedit, personalinfoedit, parentalinfoedit]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    // console.log(date);

    
    const options = { day: "numeric", month: "long", year: "numeric" };
    if (date.toLocaleDateString("en-GB", options) === "Invalid Date") return "000-00-00"
      return date.toLocaleDateString("en-GB", options);
  }
  
  return (
    <div className="StudentGeneralInfodiv">
      <div className="StudentGeneralInfodivCard">
        <div className="header" style={{display:'flex'}}>
          Student info
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setstudentinfoedit(true);
            }}
          />
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>FIRST NAME</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && data.first_name}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>LAST NAME</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>CLASS & SECTION</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && `${data.class}-${data.section}`}
            </div>
            {/* <div className="StudentGeneralInfodivCardItembody">
              <span>ROLL NUMBER</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && data.student_id === null ? "null" : data.student_id}
            </div> */}
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>PEN NUMBER</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.pen_number) }
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>ADMISSION DATE</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.admission_date)}
            </div>
            <div
              className="StudentGeneralInfodivCardItembody"
              style={{ gap: "0.5rem" }}
            >
              <span>ENROLLMENT STATUS</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && (
                <StatusButton
                  status={
                    data.enrolment_status === "active" ? "Active" : "Not Active"
                  }
                />
              )}
            </div>
            {/* <div className="StudentGeneralInfodivCardItembody">
              <span>SIBLINGS</span>
              <div style={{ display: "flex", gap: "0.4rem" }}>
                K Manoj Verma <div className="classspan">VI-A</div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="header">
          Personal info
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setpersonalinfoedit(true);
            }}
          />
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>DATE OF BIRTH</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && formatDate(data.dob)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>GENDER</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.gender)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Aadhar Number</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.aadhaar_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>BLOOD GROUP</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.blood_group)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
          <div className="StudentGeneralInfodivCardItembody">
              <span>SUN SIGN</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.zodiac_sign)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>HEALTH CONDITIONS</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.health_conditions)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>REMARKS</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.remarks)}
            </div>
          </div>
        </div>
        <div className="header">
          Father info {fatherinfo.is_guardian
          &&<div className="relationspan" style={{fontSize:'14px'}}>Guardian</div>} {fatherinfo.is_emergency_contact && <div className="relationspan" style={{fontSize:'14px'}}>Emergency Contact</div>}
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setparentalinfoedit(true);
              setparentalsliderdata( {...fatherinfo,
                relationship: "Father"
              });
            }}
          />
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>First Name</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(fatherinfo.first_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Last Name</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(fatherinfo.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Phone Number              </span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(fatherinfo.contact_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Email ID</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(fatherinfo.email)}
            </div>
            
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
          <div className="StudentGeneralInfodivCardItembody">
              <span>Occupation</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(fatherinfo.occupation)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Annual Income</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(fatherinfo.annual_income)}
            </div>
          </div>
        </div>
        <div className="header">
          Mother info {motherinfo.is_guardian
          &&<div className="relationspan" style={{fontSize:'14px'}}>Guardian</div>} {motherinfo.is_emergency_contact && <div className="relationspan" style={{fontSize:'14px'}}>Emergency Contact</div>}
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setparentalinfoedit(true);
              setparentalsliderdata( {...motherinfo,
                relationship: "Mother"
              });
            }}
          />
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>First Name</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(motherinfo.first_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Last Name</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(motherinfo.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Phone Number              </span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(motherinfo.contact_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Email ID</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(motherinfo.email)}
            </div>
            
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
          <div className="StudentGeneralInfodivCardItembody">
              <span>Occupation</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(motherinfo.occupation)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Annual Income</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(motherinfo.annual_income)}
            </div>
          </div>
        </div>
        <div className="header">
          Guardian info {guardianinfo.is_guardian
          &&<div className="relationspan" style={{fontSize:'14px'}}>Guardian</div>} {guardianinfo.is_emergency_contact && <div className="relationspan" style={{fontSize:'14px'}}>Emergency Contact</div>}
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setparentalinfoedit(true);
              setparentalsliderdata( {...guardianinfo,
                relationship: "Guardian"
              });
            }}
          />
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>First Name</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(guardianinfo.first_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Last Name</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(guardianinfo.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Phone Number              </span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(guardianinfo.contact_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Email ID</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(guardianinfo.email)}
            </div>
            
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
          <div className="StudentGeneralInfodivCardItembody">
              <span>Occupation</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(guardianinfo.occupation)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Annual Income</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(guardianinfo.annual_income)}
            </div>
          </div>
        </div>
      </div>
      {studentinfoedit && (
        <StudentInfoEditSilder data={data} sliderstate={setstudentinfoedit} />
      )}
      {personalinfoedit && (
        <PersonalInfoEditSilder data={data}  sliderstate={setpersonalinfoedit} />
      )}
      {/* {guardianinfoedit && (
        <GuardianInfoEditSlider data={data} sliderstate={setguardianinfoedit} />
      )} */}
      {parentalinfoedit && <ParentalInfoEditSlider studata={data} data={parentalsliderdata}  sliderstate={setparentalinfoedit}/>}
    </div>
  );
};
