import React, { useEffect, useState } from "react";

import { CalendarIcon, CrossMark } from "../../../assets/svgs";
import { createholidayinfo,editholidayinfo } from "../../../api/api";
import { MultiDateSelector } from "../../../home/components/MultiDateSelector";
import { toast } from "sonner";

export const AddHolidayModal = ({
    headertext,
    dropdownlabeltext,
    data,
    setpopup,
    popupdata=[],
    setpopupdata=()=>{},
    task,
    refreshdata=()=>{},
    bulk
  }) => {
    // const [loading, setloading] = useState(true);
    // const []
    // const [bulkclasschange, setbulkclasschange] = useState(null);
    const [editdata, editsetdata] = useState({ "time_table_id":18});
    useEffect(() => {
    if(popupdata.length){
      
editsetdata({...editdata,holiday_from_date:popupdata[1],holiday_to_date:popupdata[2],holiday_title:popupdata[0],holiday_id:popupdata[3]})
    }
    //eslint-disable-next-line 
    }, [popupdata]);
    function calculateDaysBetween(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDifference = end - start;
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      return daysDifference;
  }
  
    const handledatachange = async (key, value) => {
      console.log(key, value[0],value[1]);
      if(key==="holiday_dates"){
        const numberOfDays = calculateDaysBetween(value[0],value[1])+1;
        editsetdata({...editdata,holiday_from_date:value[0],holiday_to_date:value[1],number_of_days:numberOfDays})
      }
      else{
        editsetdata({...editdata,[key]:value,"time_table_id":18});
      }
    
    };
    const handlesavechanges = async () => {
      try {
        if(popupdata.length===0)
      { const res=await createholidayinfo(editdata);
        if(res.errors.error==='RESOURCE_NOT_FOUND'){
          toast.error("Please Create a TimeTable First", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      }
      else {
        await editholidayinfo(editdata);
      }
        setpopup(false); 
        refreshdata();
      } catch (error) {
        console.error("Error creating holiday info", error);
      }
    };
    return (
      <div className="AssignandMoveClassPopup">
        <div className="AssignandMoveClassPopupBody">
          <div className="AssignandMoveClassPopupBodyHeader">
            <div className="ItemSeparator" style={{color:'rgba(14, 18, 27, 1)'}}>
              <div className="AssignandMoveClassPopupBodyHeadercircle">
                <CalendarIcon />
              </div>
              Add Holiday 
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setpopup(false);
                setpopupdata([]);
              }}
            >
              <CrossMark />
            </div>
          </div>
          <div className="AssignandMoveClassPopupBodyBody">
        <div style={{display:'flex',color:'rgba(14, 18, 27, 1)',fontWeight:'500'}}>

          Title of the Holiday
          <div className="mandatory" style={{color:'rgba(251, 55, 72, 1)'}}>*</div>
        </div>
        <input type="text" className="inputbox" value={editdata.holiday_title} style={{width:'23rem',outline: 'none'}} onChange={(event)=>{handledatachange("holiday_title",event.target.value)}} placeholder="Ex. Dusshera "/>
        <div style={{display:'flex',color:'rgba(14, 18, 27, 1)',fontWeight:'500'}}>

        Holiday Date
          <div className="mandatory" style={{color:'rgba(251, 55, 72, 1)'}}>*</div>
        </div>
       <MultiDateSelector
         handledatachange={handledatachange}
         field={"holiday_dates"}
         width={"23rem"}
         data={editdata}
         value={editdata.admission_date}/>

         <div className="DangerboxContent" style={{backgroundColor:'rgba(192, 213, 255, 0.25)',color:'rgba(18, 35, 104, 1)',borderColor:'rgba(192, 213, 255, 1)',width:'22.5rem',borderRadius:'4px',display:'flex',height:'1.3rem'}}>
         Holidays will be reflected in Timetable schedules 
         </div>
         <div className="popup-footer">
            <button
              className="cancel-button"
              // onClick={() => setpopup(false)}
              onClick={() => {
                setpopupdata([]);
                setpopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className="save-button"
              // onClick={() => setpopup(false)}
              onClick={() => {
                handlesavechanges();
                // setpopup(false);
              }}
            >
              Continue
            </button>
          </div>
          </div>
        </div>
      </div>
    );
}
