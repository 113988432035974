// api.js
// const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_BASE_URL = process.env.REACT_APP_API_URL;
// console.log("This is the API_BASE_URL", API_BASE_URL);

const getAuthToken = () => {
  if (localStorage.getItem("authToken") !== undefined) {
    return localStorage.getItem("authToken");
  }
  localStorage.clear();
};

// Function to submit form data
export const handleContinue = async (
  handleprogress,
  componentname,
  input_data
) => {
  console.log("hello in api", input_data);
  try {
    if (componentname === "FeeStructureDetails") {
      const data = await submitFeeStructureDetails(input_data);
      // console.log(data.status)
      if (data.status === 201) {
        //eslint-disable-next-line
        const navigateNext = handleprogress(1);
      } else {
        //     //throw error
      }
    } else if (componentname === "FeeComponentDetails") {
      // handleprogress(2);
      for (let i = 0; i < input_data.length; i++) {
        const data = await submitFeeComponentDetails([input_data[i]]);
        if (data.status === 201) {
          //eslint-disable-next-line
          const navigateNext = handleprogress(2);
        } else {
          //     //throw error
        }
      }
    } else if (componentname === "FeeSheduleDetails") {
      //handled in feeshedulued page
    }
  } catch (error) {
    // Handle errors (e.g., show error message)
  }
};
export const submitFeeStructureDetails = async (feeStructureDetails) => {
  try {
    const authToken = getAuthToken();

    const response = await fetch(`${API_BASE_URL}/fee_structure/basic_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(feeStructureDetails),
    });
    const data = await response.json();
    console.log(data);
    const idd = data.response.fee_structure_id;
    sessionStorage.setItem("fee_structure_id", Number(idd));

    // console.log("done",data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const EditFeeStructureDetails = async (feeStructureDetails) => {
  const fee_str_id = sessionStorage.getItem("fee_structure_id");
  feeStructureDetails = { ...feeStructureDetails, id: fee_str_id };
  console.log("editing ", feeStructureDetails);
  // console.log(sessionStorage.getItem('fee_structure_id'));
  try {
    const authToken = getAuthToken();

    const response = await fetch(`${API_BASE_URL}/fee_structure/basic_info`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(feeStructureDetails),
    });
    const data = await response.json();
    console.log(data);
    // fee_structure_id = data.response.fee_structure_id;
    // console.log(fee_structure_id);
    // console.log("done",data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const getCategories = async () => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/categories?offset=0&limit=25`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    console.log("hehee", data.response.categories);
    return data.response.categories;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const getCategories2 = async () => {
  try {
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/categories?offset=0&limit=25`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    data = data.response.categories;
    const categoriesObject = data.reduce((acc, item) => {
      acc[item.id] = item.category;
      return acc;
    }, {});
    console.log("heyhii", categoriesObject);
    return categoriesObject;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const createFeeComponent = async (data1) => {
  // console.log(categoryname);
  try {
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/categories?offset=10&limit=20`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(data1),
      }
    );
    const data = await response.json();
    console.log("doneeeeeee");
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const submitFeeComponentDetails = async (feeComponentDetails) => {
  console.log(feeComponentDetails);
  const fee_str_id = sessionStorage.getItem("fee_structure_id");
  feeComponentDetails = {
    ...feeComponentDetails[0],
    fee_structure_id: fee_str_id,
  };
  console.log(feeComponentDetails);
  try {
    const authToken = getAuthToken();
    const response = await fetch(`${API_BASE_URL}/fee_structure/components`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(feeComponentDetails),
    });
    const data = await response.json();
    const fee_component_id = data.response.fee_component_id;
    sessionStorage.setItem("fee_component_id", Number(fee_component_id));
    // console.log("fee_component_id", fee_component_id);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const EditFeeComponentDetails = async (feeComponentDetails) => {
  console.log(feeComponentDetails);
  const fee_component_id = sessionStorage.getItem("fee_component_id");
  const fee_structure_id = sessionStorage.getItem("fee_structure_id");
  feeComponentDetails = {
    ...feeComponentDetails[0],
    id: fee_component_id,
    fee_structure_id: fee_structure_id,
  };
  console.log(feeComponentDetails);
  try {
    const authToken = getAuthToken();
    const response = await fetch(`${API_BASE_URL}/fee_structure/components`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(feeComponentDetails),
    });
    // const data = await response.json();
    // fee_component_id=data.response.fee_component_id;
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const handleconcession = async (data1) => {
  const fee_structure_id = sessionStorage.getItem("fee_structure_id");
  data1 = { ...data1, id: fee_structure_id };
  // data1=JSON.stringify(data1);
  console.log(data1);
  try {
    const authToken = getAuthToken();
    const response = await fetch(`${API_BASE_URL}/fee_structure/concession`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(data1),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const submitFeeSheduleDetails = async (data1) => {
  const fee_structure_id = sessionStorage.getItem("fee_structure_id");
  data1 = { ...data1, fee_structure_id: fee_structure_id };
  try {
    const authToken = getAuthToken();
    const response = await fetch(`${API_BASE_URL}/fee_schedule/basic_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(data1),
    });
    const data = await response.json();
    const fee_shedule_id = data.response.fee_schedule_id;
    sessionStorage.setItem("fee_schedule_id", Number(fee_shedule_id));
    console.log(data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const EditFeeSheduleDetails = async (data1, index) => {
  const fee_structure_id = sessionStorage.getItem("fee_structure_id");
  const fee_shedule_id = sessionStorage.getItem("fee_schedule_id") - index;
  data1 = { ...data1, fee_structure_id: fee_structure_id, id: fee_shedule_id };
  console.log(data1);
  try {
    const authToken = getAuthToken();
    const response = await fetch(`${API_BASE_URL}/fee_schedule/basic_info`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(data1),
    });
    const data = await response.json();
    console.log(data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getFeeStructure = async (branch_id) => {
  try {
    const fee_structure_id = sessionStorage.getItem("fee_structure_id");
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/?id=${fee_structure_id}&branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    console.log("inpaicall", data.response.fee_structures);
    return data.response.fee_structures[0];
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getFeeShedule = async () => {
  try {
    const fee_structure_id = sessionStorage.getItem("fee_structure_id");
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/fee_schedule/basic_info?fee_structure_id=${fee_structure_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data.response.fee_schedules;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getTransactionDetails = async (
  term,
  annual,
  branch_id,
  activetab
) => {
  let filter = annual === true ? "annual=1" : `term=${term}`;
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  if (activetab === "Day") {
    filter = `date=${formattedDate}`;
  } else if (activetab === "Weekly") {
    filter = `week=${formattedDate}`;
  } else if (activetab === "Monthly") {
    filter = `month=${month}`;
  }
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/fee_dashboard/transactions?${filter}&branch_id=${branch_id}&offset=0&limit=1000`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    console.log("hhhhh");
    const data = await response.json();
    console.log(data["response"]);
    console.log("hhhhh");
    return data["response"];
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getFeeDashBoardStats = async (termid, branch_id, annual) => {
  const annualfilter = annual === true ? "&annual=1" : "";
  try {
    const authToken = getAuthToken();

    const response = await fetch(
      `${API_BASE_URL}/fee_dashboard/stats?term=${termid}&branch_id=${branch_id}${annualfilter}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data["response"];
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getFeeComponent = async () => {
  try {
    const fee_structure_id = sessionStorage.getItem("fee_structure_id");
    const authToken = getAuthToken();

    const response = await fetch(
      `${API_BASE_URL}/fee_structure/components?fee_structure_id=${fee_structure_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        // body: JSON.stringify(feeStructureDetails),
      }
    );

    const data = await response.json();
    console.log(
      "fetching fee component data",
      data["response"]["fee_components"]
    );
    return data["response"]["fee_components"];
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getClasses = async (branch_id) => {
  try {
    const authToken = getAuthToken();

    const response = await fetch(
      `${API_BASE_URL}/school_directory/classes?branch_id=${branch_id}&offset=0&limit=1000`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        priority: "low",
        // body: JSON.stringify(feeStructureDetails),
      }
    );

    const data = await response.json();
    console.log(data["response"]["all_classes"]);
    return data["response"]["all_classes"];
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const getSectionsBasedOnclassID = async (class_id = 3) => {
  try {
    const authToken = getAuthToken();

    const response = await fetch(
      `${API_BASE_URL}/school_directory/mapped_sections?class_id=${class_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        priority: "low",
        // body: JSON.stringify(feeStructureDetails),
      }
    );

    const data = await response.json();
    // return result;
    // console.log(data["response"]["sections"]);
    return data["response"]["sections"];
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getPendingFeeDetails = async (
  termFilter,
  sortFilter,
  lowFilter,
  highFilter,
  maxTerms,
  gradesFilter,
  branch_id
) => {
  try {
    if (termFilter === "annual" || termFilter === "term") {
      termFilter = maxTerms;
    } else {
      var regex = /^term\s\d+$/;
      if (regex.test(termFilter)) {
        termFilter = termFilter.split(" ")[1];
      }
    }

    let url = `${API_BASE_URL}/fee_collection?term=${termFilter}&sort=${sortFilter}&min_range=${lowFilter}&max_range=${highFilter}&branch_id=${branch_id}&offset=0&limit=1000`;
    if (
      gradesFilter !== "all" &&
      gradesFilter !== null &&
      gradesFilter !== undefined
    ) {
      url += `&class_section_mapping_ids=${gradesFilter}`;
    }

    const authToken = getAuthToken();
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

        Authorization: authToken,
      },
    });
    const data = await response.json();
    return data.response.transactions;
  } catch (error) {
    throw error;
  }
};

export const login = async (formData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/user/get_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllTerms = async (branch_id) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/max_terms?branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        priority: "high",
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserInfo = async () => {
  const token = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/getUserInfo`,
      {
        priority: "high",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ token }),
      }
    );
    const data = await response.json();
    return data.response;
  } catch (error) {
    throw error;
  }
};

export const getUserFeePayment = async (user_id, fee_structureId) => {
  const token = getAuthToken();

  let url = `${API_BASE_URL}/fee_collection/student_fee?user_id=${user_id}`;
  if (
    fee_structureId !== undefined &&
    fee_structureId !== "" &&
    fee_structureId !== null
  ) {
    url += `&fee_structure_id=${fee_structureId}`;
  }
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await response.json();
    console.log("This is the getUserFeePayment", data);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addFeePayment = async (formData) => {
  const token = getAuthToken();

  formData.feeCollections = formData.feeCollections.filter(
    (item) => item.amount_paid !== 0
  );

  try {
    const response = await fetch(`${API_BASE_URL}/fee_collection/collect`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
export const getAllFeeStructuresNames = async (branch_id) => {
  console.log(branch_id);
  let url = `${API_BASE_URL}/fee_structure/?branch_id=${branch_id}&limit=50`;
  const token = getAuthToken();
  try {
    const response = await fetch(url, {
      priority: "auto",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    let data = await response.json();
    data = data.response.fee_structures.map((fee) => fee.name);
    // console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
};
export const getAllFeeStructures = async (
  statusFilter,
  branch_id,
  gradesFilter
) => {
  const token = getAuthToken();
  let url = `${API_BASE_URL}/fee_structure/?branch_id=${branch_id}&limit=50`;

  if (statusFilter === "active") {
    url += "&active=1";
  } else if (statusFilter === "inactive") {
    url += "&active=0";
  }

  if (
    gradesFilter !== "all" &&
    gradesFilter !== null &&
    gradesFilter !== undefined
  ) {
    url += `&class_section_mapping_ids=${gradesFilter}`;
  }

  try {
    const response = await fetch(url, {
      priority: "auto",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const toggleStatus = async (formData) => {
  const token = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/toggle_status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(formData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDashboardStats = async (
  termFilter,
  maxTerms,
  branch_id,
  gradesFilter
) => {
  if (termFilter === "annual" || termFilter === "term") {
    termFilter = maxTerms;
  } else {
    var regex = /^term\s\d+$/;
    if (regex.test(termFilter)) {
      termFilter = termFilter.split(" ")[1];
    }
  }

  let url = `${API_BASE_URL}/fee_dashboard/stats?term=${termFilter}&branch_id=${branch_id}`;

  if (
    gradesFilter !== "all" &&
    gradesFilter !== null &&
    gradesFilter !== undefined
  ) {
    url += `&class_section_mapping_ids=${gradesFilter}`;
  }

  try {
    const authToken = getAuthToken();
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    });
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteFeeStructure = async (id) => {
  const token = getAuthToken();
  console.log("delete", id);
  if (!id) {
    id = sessionStorage.getItem("fee_structure_id");
  }
  try {
    const response = await fetch(`${API_BASE_URL}/fee_structure/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ id: id }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFeeStructureByID = async (fee_structureId, branch_id) => {
  try {
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/?id=${fee_structureId}&branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data.response.fee_structures[0];
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getFeeScheduleById = async (fee_structureId) => {
  try {
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/fee_schedule/basic_info?fee_structure_id=${fee_structureId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data.response.fee_schedules;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getReceiptDetails = async (receipt_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/fee_collection/fee_receipt?receiptId=${receipt_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFeeComponentById = async (fee_structureId) => {
  try {
    const authToken = getAuthToken();

    const response = await fetch(
      `${API_BASE_URL}/fee_structure/components?fee_structure_id=${fee_structureId}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );

    const data = await response.json();
    return data["response"]["fee_components"];
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getBranchDetails = async (type_id, id) => {
  const authToken = getAuthToken();
  let url = `${API_BASE_URL}/school_directory/organizations_branches`;

  if (type_id === "org") {
    url += `?organization_id=${id}`;
  } else {
    url += `?branch_id=${id}`;
  }
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      priority: "high",
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getClassesBasedOnBranchId = async (branch_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/mapped_sections?branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const sendBulkSMS = async (formData, school_name, use_case) => {
  const authToken = getAuthToken();

  const payload = {
    use_case: use_case,
    school_name: school_name,
    messages: formData,
  };
  try {
    const response = await fetch(`${API_BASE_URL}/communication/bulk-sms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// export const getAcademicProgramDetails = async (organization_id) => {
//   const authToken = getAuthToken();
//   let url = `${API_BASE_URL}/school_directory/academic_program?organization_id=${organization_id}`;
//   try {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: authToken,
//       },
//       priority: "high",
//     });
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     throw error;
//   }
// };

export const getFeeTransactionAPI = async (
  branch_id,
  user_id,
  lowFilter,
  highFilter,
  sortFilter
) => {
  const authToken = getAuthToken();
  let url = `${API_BASE_URL}/fee_dashboard/transactions?branch_id=${branch_id}&user_id=${user_id}&offset=0&limit=1000`;

  if (
    lowFilter !== "" &&
    highFilter !== "" &&
    lowFilter !== undefined &&
    highFilter !== undefined &&
    lowFilter !== null &&
    highFilter !== null
  ) {
    url += `&min_range=${lowFilter}&max_range=${highFilter}`;
  }

  if (sortFilter) {
    url += `&sort=${sortFilter}`;
  } else {
    url += `&sort=asc`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    });
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStudent = async (user_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_info?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        priority: "high",
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGuardianDetails = async (user_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student/guardian?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFeeStructureBySectionID = async (branch_id,section_id, class_id) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/fee_structure?branch_id=${branch_id}&section_id=${section_id}&class_id=${class_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getFeeAssociatedClassSection = async (branch_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/fee_structure/fee_associated_class_section_mapping_ids?branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    data = data.response.results;
    return data;
  } catch (error) {
    throw error;
  }
};

export const getClassesById = async (class_id, section_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/mapped_sections?class_id=${class_id}&section_id=${section_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// api/api.js
export const createFeeConcession = async (formData, method = "POST") => {
  const authToken = getAuthToken();

  const validMethods = ["POST", "PUT"];
  const requestMethod = validMethods.includes(method.toUpperCase())
    ? method.toUpperCase()
    : "POST";

  try {
    const response = await fetch(`${API_BASE_URL}/student/fee_concession`, {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(formData),
    });
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFeeConcessionById = async (user_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/student/fee_concession?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPendingFeeDetailsById = async (branch_id,user_id) => {
  try {
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/fee_collection?term=1&branch_id=${branch_id}&user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    console.log("data", data);
    return data.response;
  } catch (error) {
    throw error;
  }
};

export const createOrganizationSetup = async (formData) => {
  const authToken = getAuthToken();

  if (formData.multiple_branches === 0) {
    formData.total_branches = 1;
  }

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/school_admin_signup
`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(formData),
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAffiliateBoard = async () => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/affiliate_board`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSchoolLevels = async () => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/school_levels`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllSchoolStructures = async (branch_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/school_structure?offset=0&limit=100&branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllAffiliatedSubjectsWithId = async (board_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/affiliated_subjects?board_id=${board_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSchoolDetails = async (branch_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/school_info?branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSchoolStructureById = async (branch_id,id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/school_structure?branch_id=${branch_id}&class_section_mapping_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllSchoolTeachers = async () => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/profile_info/?role=teacher&branch_id=${1}&offset=16&limit=1000`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAffiliatedBoardSubjects = async (board_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/affiliated_subjects?board_id=${board_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSubjectsWithTeachers = async (class_section_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/subject_teacher?class_section_mapping_id=${class_section_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addTeacherAndIncharge = async (formData) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/class_teacher_acad_incharge`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(formData),
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTeacherDetailsWithID = async (id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/teachers?class_section_mapping_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const schoolAcadSetup = async (formData) => {
  console.log("formData", formData);
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/school_acad_setup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(formData),
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const bulkTeacherSetup = async (formData) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/subject_teacher`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(formData),
      }
    );
    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getConcessionRefereeNames = async (id = null) => {
  let filter = "";
  if (id != null) {
    filter = `id=${id}`;
  }
  try {
    const authToken = getAuthToken();
    const response = await fetch(
      `${API_BASE_URL}/student/fee_concession_reference?${filter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    console.log("data1234", data);
    return data.response.results;
  } catch (error) {
    throw error;
  }
};

export const addExistingConcessionRefereForaStudent = async (body) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/student/fee_concession_reference`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
export const createnewConcessionRefere = async (body) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/student/fee_concession_reference`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
export const getExistingReference = async (user_id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/student/fee_concession?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data.response.fee_concessions;
  } catch (error) {
    throw error;
  }
};
export const getStudentsDetailsStudentDirectory = async (branch_id,value = []) => {
  // For all Students Details
  let filter = "";
  for (let i = 0; i < value.length; i += 2) {
    filter += `${value[i]}=${value[i + 1]}&`;
    console.log(filter);
  }
  console.log(filter);
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_info/?branch_id=${branch_id}&offset=0&limit=1000&${filter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();

    return data.response;
  } catch (error) {
    throw error;
  }
};

export const getStudentsDetailsStudentDirectoryWithClassSectionMappingId =
  async (value = [], branch_id, class_section_mapping_id) => {
    let filter = "";
    for (let i = 0; i < value.length; i += 2) {
      filter += `${value[i]}=${value[i + 1]}&`;
    }
    const authToken = getAuthToken();
    try {
      const response = await fetch(
        `${API_BASE_URL}/school_directory/student_info/?branch_id=${branch_id}&offset=0&limit=1000&${filter}&class_section_mapping_id=${class_section_mapping_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );
      let data = await response.json();

      return data.response;
    } catch (error) {
      throw error;
    }
  };

export const getStudentDetailsStudentDirectory = async (user_id) => {
  // for Individual Student
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_info/?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    let data = await response.json();

    return data.response.students[0];
  } catch (error) {
    throw error;
  }
};
export const deletstudentprofile = async (ids) => {
  const authToken = getAuthToken();
  console.log(ids);
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_info/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify({ user_ids: ids }),
      }
    );
    let data = await response.json();
    return data.response.message;
  } catch (error) {
    throw error;
  }
};
export const editstudentinfo = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_info/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    return response.message;
  } catch (error) {
    throw error;
  }
};
export const addstudentinfo = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_info/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    return response.message;
  } catch (error) {
    throw error;
  }
};
export const editpersonalinfo = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/profile_info/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    return response.message;
  } catch (error) {
    throw error;
  }
};
export const editguardianinfo = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_guardian_info`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    return response.message;
  } catch (error) {
    throw error;
  }
};

export const assignandmoveclass = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/assign_class`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    return response.message;
  } catch (error) {
    throw error;
  }
};

export const getStaffdetails = async (filter) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/staff_info?${filter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data.response.staff;
  } catch (error) {
    throw error;
  }
};

export const createStudent = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_info/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    const data1 = await response.json();
    return data1.response.message;
  } catch (error) {
    throw error;
  }
};

export const getSubjectBasedOnBranchID = async (id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/subjects?branch_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    return data.response.all_subjects;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getholidayinfo = async (id=18) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/holidayinfo?time_table_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    return data.response.holidays;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const deleteholidayinfo = async (id) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/holidayinfo?holiday_id=${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const editholidayinfo = async (data1) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/holidayinfo`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(data1),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const createholidayinfo = async (data1) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/holidayinfo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(data1),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const createtimetable = async (data1) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/timetable/timetable_info`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(data1),
      }
    );
    const data = await response.json();
    console.log(data.response.timetable_id
    );
    return data.response.timetable_id;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const BulkAssignSubjecttoTeacher = async (id,data1) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/timetable/assignperiod?time_table_id=${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(data1),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getTimetabledata = async (ttid,filter) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/timetable/timetabledata?time_table_id=${ttid}&${filter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    return data.response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const getPeriodDuration = async (ttid,seq) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/timetable/getperiodduration?time_table_id=${ttid}&period_seq_num=${seq}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    return data.response.period_duration;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};
export const getTimetableId = async (filter) => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/timetable/gettimetableid?${filter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    return data.response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const deletesubjectTeacher = async (id) => {
  const authToken = getAuthToken();
  console.log(id);
  
  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/subject_teacher`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify({id:id}),
      }
    );
    let data = await response.json();
    return data.response.message;
  } catch (error) {
    throw error;
  }
};

export const getTimetabletitlewithclasses= async () => {
  const authToken = getAuthToken();
  try {
    const response = await fetch(
      `${API_BASE_URL}/timetable/timetable_info`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    const data = await response.json();

    return data.response.time_table_data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error for handling in the caller
  }
};

export const getStudentParentalInfo = async (id,relation="") => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_parental_info?student_user_id=${id}${relation!==""?("&relationship="+relation):""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
      }
    );
    const data = await response.json();
    return data.response;
  } catch (error) {
    throw error;
  }
};

export const editStudentParentalInfo = async (id,relation="",data1) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_parental_info?student_user_id=${id}&relationship=${relation}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data1),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const createStudentParentalInfo = async (id,relation="",data1) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/student_parental_info?student_user_id=${id}&relationship=${relation}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data1),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const createStaff = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/staff_info`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    const data1 = await response.json();
    return data1.response.message;
  } catch (error) {
    throw error;
  }
};

export const deletstaffprofile = async (ids) => {
  // single and bulk
  const authToken = getAuthToken();
  console.log(ids);

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/staff_info/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify({ user_ids: ids }),
      }
    );
    let data = await response.json();
    return data.response.message;
  } catch (error) {
    throw error;
  }
};

export const createAcademicSubject = async (data) => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${API_BASE_URL}/school_directory/academic_subject`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: authToken,
        },
        body: JSON.stringify(data),
      }
    );
    const data1 = await response.json();
    return data1.response.id;
  } catch (error) {
    throw error;
  }
};