import React, { useContext, useState,useEffect } from "react";
import RouteHeader from "../../FeeManagementModule/components/RouteHeader";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { RxCross1 } from "react-icons/rx";
import { CancelButton } from "../../FeeManagementModule/FeeStructureModule/components/CancelButton";
import { toast } from "sonner";
import { IoIosArrowForward } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { AcademicStaffInfo } from "./AcademicStaffInfo";
import { StaffPersonalInfo } from "./StaffPersonalInfo";
import { StaffProfessionalInfo } from "./StaffProfessionalInfo";
import { StaffPreviewandFinalise } from "./StaffPreviewandFinalise";
import { createStaff } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../context/UserContext";

export const AddAcademicStaff = ({ type }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [progress, setprogress] = useState(0);
  const [buttonstate, setbuttonstate] = useState(false);
  const dynamicStyles = {
    display: "flex",
    background: "rgb(245, 247, 250)",
    color: "black",
    // pointerEvents: "none",
    cursor: "not-allowed",
  };
  const routes = [
    { number: 1, text: "Academic Staff Info", path: "/signup/setup/admin" },
    { number: 2, text: "Personal Info", path: "/signup/setup/school" },
    {
      number: 3,
      text: "Professional Info",
      path: "/signup/setup/academic",
    },
    { number: 4, text: "Finalise", path: "/signup/finalise" },
  ];
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [data, setdata] = useState({
    staff_type: type === "Add Admin Staff" ? "admin_staff" : "academic_staff",
    // role: type === "Add Admin Staff" ? "admin" : "teacher",
    background_check:"Active"
  });
  const formatrole=(input)=>{
    return input.toLowerCase().replace(/\s+/g, '_');

  }
  const senddata = async () => {
    console.log(data);
    const res = await createStaff({
      ...data,
      role: formatrole(data.role),
      branch_id: userInfo.branch_id,
      contract_type:
        data.contract_type === "Full Time" ? "full_time" : data.contract_type,
      background_check:
        data.background_check === "Active" ? "clear" : "pending",
    });
    console.log(res);
    if (res === "RESOURCE_CREATED_SUCCESSFULLY") {
      toast.success("Successfully Added Staff", {
        position: "top-right",
        autoClose: 5000,
      });

      navigate("/staffdirectory");
    } else {
      toast.success("Staff Addition unsuccessfull", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
  const findmandatorychecks=(data1=null)=>{
    if(data1===null){setbuttonstate(false); return;}
    if(progress===0){
      if(!data1.first_name || data1.first_name==="") { setbuttonstate(false); return; }
      if(!data1.phone_number || data1.phone_number==="") { setbuttonstate(false); return; }
      if(!data1.role || data1.role==="") { setbuttonstate(false); return; }
    }
    else if(progress===1){
      if(!data1.name_of_emergency_contact || data1.name_of_emergency_contact==="") { setbuttonstate(false); return; }
      if(!data1.emergency_contact || data1.emergency_contact==="") { setbuttonstate(false); return; }
      if(!data1.relation_of_emergency_contact || data1.relation_of_emergency_contact==="") { setbuttonstate(false); return; }
    }
    else if(progress===2){
      if(!data1.primary_subject || data1.primary_subject==="") { setbuttonstate(false); return; }
    }
    // console.log(data1,progress);

    setbuttonstate(true);
  }
  useEffect(() => {

    findmandatorychecks(data);
    //eslint-disable-next-line
  }, [progress]);
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <RouteHeader
      mainroute="/staffdirectory"
        routeHeader="Staff Directory"
        routes={[{ name: type, link: "" }]}
        search
      />
      <div className="headeradd">{type}</div>
      <div
        className="addstudentdiv"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "80%",
          overflow: "hidden",
        }}
      >
        <div
          className="AddStudentBody"
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "10rem",
          }}
        >
          <div className="SetupBarHeader" style={{ flexShrink: 0 }}>
            <div className="backbutton">
              <header className="setup-header">
                <div className="setup-header__routes">
                  {routes.map((route, index) => {
                    const isCompleted = index < progress;
                    const isCurrentRoute = index === progress;

                    return (
                      <div
                        key={route.number}
                        className={`setup-header__route ${
                          isCurrentRoute ? "current-route" : ""
                        }`}
                      >
                        {isCompleted ? (
                          <span className="setup-header__route-number route--checked">
                            <FaCheck size={"0.65rem"} />
                          </span>
                        ) : (
                          <span
                            className={`setup-header__route-number ${
                              isCurrentRoute ? "current-route-number" : ""
                            }`}
                          >
                            {route.number}
                          </span>
                        )}
                        <div
                          className={`setup-header__route-text ${
                            isCompleted || isCurrentRoute ? "checked-text" : ""
                          }`}
                          onClick={() => {
                            if (progress > index) setprogress(index);
                          }}
                        >
                          {route.text}
                        </div>
                        {index < routes.length - 1 && (
                          <IoIosArrowForward
                            size={"0.85rem"}
                            className="setup-header__route-arrow"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </header>
            </div>
            <div>
              <button
                onClick={() => {
                  setIsCancelModalOpen(!isCancelModalOpen);
                }}
                style={{ cursor: "pointer" }}
                className="Cancel"
              >
                Cancel{" "}
                <RxCross1 className="crossicon" size={11} strokeWidth={0.5} />
              </button>
              <CancelButton
                isOpen={isCancelModalOpen}
                onClose={() => {
                  setIsCancelModalOpen(!isCancelModalOpen);
                }}
                navto={"/staffdirectory"}
              />
            </div>
          </div>
          <div
            className="AddStudentBodyComponents"
            style={{ flex: "1", overflowY: "auto", padding: "16px" }}
          >
            <button
              type="submit"
              className="back-button"
              onClick={() => {
                if (progress > 0) setprogress(progress - 1);
              }}
            >
              <div className="lefticon">
                <SlArrowLeft size={10} strokeWidth={90} />{" "}
              </div>
              Back
            </button>
            {progress === 0 && (
              <AcademicStaffInfo findmandatorychecks={findmandatorychecks} data={data} setdata={setdata} />
            )}
            {progress === 1 && (
              <StaffPersonalInfo
                data={data}
                findmandatorychecks={findmandatorychecks}
                setdata={setdata}
                progressstate={setprogress}
              />
            )}
            {progress === 2 && (
              <StaffProfessionalInfo
                data={data}
                setdata={setdata}
                findmandatorychecks={findmandatorychecks}
                progressstate={setprogress}
              />
            )}
            {progress === 3 && (
              <StaffPreviewandFinalise data={data} setdata={setdata} />
            )}
            <div> </div>
          </div>
          {/* <div className="buttonpopup-container">
          <div className="buttonpopup">
            <h2>buttonpopup Content</h2>
            <p>This is the content of the buttonpopup.</p>
            <button >Close</button>
          </div>
        </div> */}
          <div style={{ position: "relative" }}>
            <div className="buttonpopup-container">
              <button
                className="buttonpopup"
                style={
                  buttonstate === false ? dynamicStyles : { display: "flex" }
                }
                onClick={async (e) => {
                  // if (progress >= 3) {
                  //   e.preventDefault();

                  //   await senddata();
                  // } else if (buttonstate === true) {
                  if (progress >= 3) {
                    senddata();
                  } else if (buttonstate === true) {
                    setprogress(progress + 1);
                  }
                  // }
                }}
              >
                {progress === 3
                  ? "Save to Create Profile"
                  : "Save and Continue"}
                {progress < 3 && (
                  <div
                    className="righticon"
                    style={{
                      marginLeft: "6px",
                      color: buttonstate === false ? "black" : "",
                    }}
                  >
                    <SlArrowRight size={9} strokeWidth={70} />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
