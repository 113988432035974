import React from "react";
import "./DeletePopup.css";
import { deletstudentprofile,deletstaffprofile } from "../../../api/api";

export const DeletePopup = ({
  setpopup,
  actionstudentids,
  refreshdata,
  bulk = false,
  dir="student"
}) => {
  console.log(actionstudentids);
  const handledelete = async () => {
    if(dir==="student"){let userResponse =true;
    if (actionstudentids.length>=50)
      userResponse=window.confirm(
        `Deleting ${actionstudentids.length} Students`
      );

    if (userResponse) {
      const resp = await deletstudentprofile(
        actionstudentids
      );
      console.log(resp);
      if (resp === "RESOURCE_DELETED_SUCCESSFULLY") {
        refreshdata();
      }
    } else {
      console.log("User clicked Cancel");
    }}
    if(dir==="staff"){
      const resp=await deletstaffprofile(actionstudentids);
      if (resp === "RESOURCE_DELETED_SUCCESSFULLY") {
        refreshdata();
      }
    }

    setpopup(false);

  };
  return (
    <div className="DeletePopup">
      <div className="DeletePopupContent">
        <div className="DeletePopupHeader">
          <div className="modal-icon">
            <svg
              width="41"
              height="40"
              viewBox="0 0 41 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 10C0.5 4.47715 4.97715 0 10.5 0H30.5C36.0228 0 40.5 4.47715 40.5 10V30C40.5 35.5228 36.0228 40 30.5 40H10.5C4.97715 40 0.5 35.5228 0.5 30V10Z"
                fill="#FFEBEC"
              />
              <path
                d="M21.2405 11.9324L29.3854 26.2027C29.4605 26.3342 29.5 26.4833 29.5 26.6351C29.5 26.7869 29.4605 26.9361 29.3855 27.0676C29.3104 27.199 29.2025 27.3082 29.0725 27.3841C28.9425 27.46 28.7951 27.5 28.645 27.5H12.355C12.2049 27.5 12.0575 27.46 11.9275 27.3841C11.7975 27.3082 11.6896 27.199 11.6145 27.0676C11.5395 26.9361 11.5 26.7869 11.5 26.6351C11.5 26.4833 11.5395 26.3342 11.6146 26.2027L19.7595 11.9324C19.8346 11.8009 19.9425 11.6918 20.0725 11.6159C20.2025 11.54 20.3499 11.5 20.5 11.5C20.6501 11.5 20.7975 11.54 20.9275 11.6159C21.0575 11.6918 21.1654 11.8009 21.2405 11.9324ZM19.645 23.1757V24.9054H21.355V23.1757H19.645ZM19.645 17.1216V21.4459H21.355V17.1216H19.645Z"
                fill="#FB3748"
              />
            </svg>
          </div>
        </div>
        {bulk && `Are you sure you want to delete these ${dir}s profile?`}
        {!bulk && `Are you sure you want to delete this ${dir} profile?`}
        <span>
          All information about the{" "}
          <span style={{ color: "red", fontSize: "20px" }}>
            {actionstudentids.length}
          </span>{" "}
          {`${dir}`}
          {actionstudentids.length > 1 ? "(s)" : ""} will be erased. This action
          cannot be undone.
        </span>
        <div className="DangerboxContent">
        {`${dir}`}{bulk && "(s)"} data will be completely erased
        </div>
        <div className="popup-footer">
          <button
            className="cancel-button"
            // onClick={() => setpopup(false)}
            onClick={() => {
              setpopup(false);
            }}
          >
            Cancel
          </button>
          <button
            className="save-button"
            // onClick={() => setpopup(false)}
            onClick={() => {
              handledelete();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
