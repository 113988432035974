import React from "react";

import {
  useOutsideClick,
  useToggle,
} from "../../../FeeManagementModule/FeeStructureModule/hooks";
import "./SubjectActions.css";
import { RiDeleteBin2Line } from "react-icons/ri";
import { deletesubjectTeacher } from "../../../../api/api";
import { toast } from "sonner";
// import { AiOutlineEdit } from "react-icons/ai";
// deletesubjectTeacher

export const SubjectActions = ({id,refreshData}) => {
  const actionsRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const { state: isOpen, handlers } = useToggle();

  useOutsideClick(actionsRef, handlers.off);

  return (
    <>
      <button
        onClick={handlers.toggle}
        className="subject-actions__button"
        ref={buttonRef}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        </svg>
      </button>
      {isOpen && (
        <div className="subject-actions__menu" ref={actionsRef}>
          <button className="subject-actions__menu-item" onClick={async()=>{
            const res = await deletesubjectTeacher(id);
            if (res === "RESOURCE_DELETED_SUCCESSFULLY") {
              toast.success("Subject deleted successfully", {
                position: "top-right",
                autoClose: 5000,
              });
              // window.location.href = `${window.location.href}?subjectrefresh`; 
              refreshData()
            }
            else{
              toast.error("Delete was unsuccessful please try again", {
                position: "top-right",
                autoClose: 5000,
              });
            }
            ;}}>
            <RiDeleteBin2Line size={"1rem"} />
            Delete
          </button>
        </div>
      )}
    </>
  );
};

// export default SubjectActions;
