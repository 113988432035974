import React, { useState } from "react";
import RouteHeader from "../../FeeManagementModule/components/RouteHeader";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import { formatRomanNumber } from "../../FeeManagementModule/FeeStructureModule/hooks";
import {
  getStaffdetails
} from "../../../api/api";
import { Outlet } from "react-router-dom";
import { StaffGeneralInfo } from "./StaffGeneralInfo";
import { findnull } from "../../StudentDirectoryModule/StudentGeneralInfo";
import { AssignClassView } from "./AssignClassView";

export const StaffProfileLayout = () => {
  const location = useLocation();
  // const [quickactionsdropdown, setquickactionsdropdown] = useState(false);
  const userId = location.pathname.split("/")[2];
  const activeLink = location.pathname.split("/")[3];
 
  // const { userInfo } = React.useContext(UserContext);

  const [isLoading, setIsLoading] = React.useState(true);
 
  const [tabs,settabs]=useState(activeLink==="generalinfo"?"general":"assignedclasses");
 const [staffdata,setstaffdata] =useState([])
 const [classesassigned,setclassesassigned]=useState([]);
  React.useEffect(() => {
    const fetchData = async() => {
      try{

        setIsLoading(true)
       const res=await getStaffdetails(`user_id=${userId}`);
       setstaffdata(res[0]);
       if(res[0].teachings){
        let temp=[]
        for(let i=0;i<res[0].teachings.length;i++){
           const className = `Class ${formatRomanNumber(res[0].teachings[i].class.split(" ")[1])}-${res[0].teachings[i].section}`
            temp.push({class:className,subject:res[0].teachings[i].subject})
        }
        setclassesassigned(temp)
       }
      }
      finally{
        setIsLoading(false)
      }
    };
    fetchData();
    
  }, [userId]);

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <RouteHeader
      mainroute="/staffdirectory"
        routeHeader="Staff Directory"
        routes={[
          {
            name: `${staffdata.name_of_the_staff}`,
            link: ``,
          },
        ]}
        search
      />
      <section className="user-transaction-log">
        <section className="user-transaction-log__left-panel">
          <div className="user-avatar">
            <img
              className="user-avatar__image"
              src={`https://api.dicebear.com/9.x/initials/svg?seed=${staffdata.name_of_the_staff}`}
              alt="Boots"
            />
          </div>

          <div
            className="user-info"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="user-info__name">{staffdata.name_of_the_staff}</p>
              <span className="cylinder-shape"> {staffdata.user_id}</span>
            </div>

            <p className="user-info__class">
              {/* {sectionData.class} - {sectionData.section} */}
              {staffdata.responsibility===null?staffdata.role:staffdata.responsibility[0].role}
            </p>

            {/* <span className="cylinder-shape"># {"132"}</span> */}
          </div>
          <button
            className="user-transaction-log__button"
            // onClick={() => {
            //   setquickactionsdropdown(!quickactionsdropdown);
            // }}
          >
            Quick Actions
            <IoIosArrowDown height={"1rem"} />
          </button>
          {/* {quickactionsdropdown && (
            <div className="actionsmenumaindiv">
              <div className="actions-menu">
                <div
                  className="menu-item"
                  // onClick={() => {
                  //   selectmenuitems(1);
                  // }}
                >
                  {" "}
                  <div className="menu-icon">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.129 5.24952L5.106 1.22652L6.1665 0.166016L12 5.99952L6.1665 11.833L5.106 10.7725L9.129 6.74952H0V5.24952H9.129Z"
                        fill="#0E121B"
                      />
                    </svg>
                  </div>{" "}
                  Assign class
                </div>
                <div
                  className="menu-item"
                  // onClick={() => {
                  //   selectmenuitems(2);
                  // }}
                >
                  {" "}
                  <div className="menu-icon">
                    <svg
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 10.689V12.2565C6.82119 12.0165 6.09471 11.9429 5.38155 12.0418C4.6684 12.1408 3.9894 12.4094 3.40157 12.8252C2.81375 13.2409 2.33426 13.7916 2.00337 14.4311C1.67249 15.0705 1.49986 15.78 1.5 16.5L1.93842e-07 16.4993C-0.000232586 15.5834 0.209191 14.6797 0.612224 13.8573C1.01526 13.0349 1.6012 12.3157 2.32516 11.7548C3.04912 11.1939 3.89188 10.8061 4.78887 10.6212C5.68585 10.4364 6.61325 10.4593 7.5 10.6883V10.689ZM6 9.75C3.51375 9.75 1.5 7.73625 1.5 5.25C1.5 2.76375 3.51375 0.75 6 0.75C8.48625 0.75 10.5 2.76375 10.5 5.25C10.5 7.73625 8.48625 9.75 6 9.75ZM6 8.25C7.6575 8.25 9 6.9075 9 5.25C9 3.5925 7.6575 2.25 6 2.25C4.3425 2.25 3 3.5925 3 5.25C3 6.9075 4.3425 8.25 6 8.25ZM10.5 12.75V10.125L14.25 13.5L10.5 16.875V14.25H8.25V12.75H10.5Z"
                        fill="#0E121B"
                      />
                    </svg>
                  </div>{" "}
                  Move between class
                </div>
                <div className="menu-item">
                  {" "}
                  <div className="menu-icon">
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.25 13.25H13.75V14.75H0.25V13.25ZM7.75 8.879L12.3032 4.325L13.3638 5.3855L7 11.75L0.63625 5.38625L1.69675 4.325L6.25 8.8775V0.5H7.75V8.879Z"
                        fill="#0E121B"
                      />
                    </svg>
                  </div>{" "}
                  Export Profile
                </div>

                <div
                  className="menu-item"
                  // onClick={() => {
                  //   selectmenuitems(3);
                  // }}
                >
                  <div className="menu-icon">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.75 3.5H15.5V5H14V14.75C14 14.9489 13.921 15.1397 13.7803 15.2803C13.6397 15.421 13.4489 15.5 13.25 15.5H2.75C2.55109 15.5 2.36032 15.421 2.21967 15.2803C2.07902 15.1397 2 14.9489 2 14.75V5H0.5V3.5H4.25V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5H11C11.1989 0.5 11.3897 0.579018 11.5303 0.71967C11.671 0.860322 11.75 1.05109 11.75 1.25V3.5ZM12.5 5H3.5V14H12.5V5ZM9.0605 9.5L10.3865 10.826L9.326 11.8865L8 10.5605L6.674 11.8865L5.6135 10.826L6.9395 9.5L5.6135 8.174L6.674 7.1135L8 8.4395L9.326 7.1135L10.3865 8.174L9.0605 9.5ZM5.75 2V3.5H10.25V2H5.75Z"
                        fill="#0E121B"
                      />
                    </svg>
                  </div>{" "}
                  Delete Profile
                </div>
              </div>
            </div>
          )} */}
          <div className="profile__underline"></div>
          <div>
            <span className="transaction__log--title">CONTACT DETAILS</span>
            <div className="guardian__details--section">
              <div className="user-info__section">
                
                +91-{findnull(staffdata.phone_number)}
                <p className="guardian-info__class">{findnull(staffdata.email)}</p>
              </div>
            </div>
          </div>
          <div>
            <span className="transaction__log--title">CLASSES ASSGINED</span>
            <div style={{ display: "flex", gap: "1rem", marginTop: "0.5rem" }}>
            {
                classesassigned.map(item => {
                  return (
                    <span className="cylinder-shape"> {item.class}</span>
                  );
                })
              }
              
              
            </div>
          </div>
        </section>
        <section className="user-transaction-log__right-panel">
          <div className="user--log__top-bar">
            <ul className="user--log__nav">
              <li>
                <NavLink
                  to={`generalinfo`}
                  className={`user--log__link ${
                    tabs === "general" ? "user--log__link--active" : ""
                  }`}
                  onClick={()=>{settabs("general")}}
                >
                  General
                </NavLink>
              </li>
              <li>
                <NavLink
                 to={`assignedclasses`}
                  onClick={()=>{settabs("assignedclasses")}}
                  className={`user--log__link ${
                    tabs === "assignedclasses" ? "user--log__link--active" : ""
                  }`}
                >
                  Assigned Classes
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                 onClick={()=>{settabs("feedback")}}
                  className={`user--log__link ${
                    tabs === "feedback" ? "user--log__link--active" : ""
                  }`}
                >
                  Feedback
                </NavLink>
              </li> */}
            </ul>
          </div>
          <section className="user--log__right-panel__content">
            <Outlet />
          {tabs==="general" && <StaffGeneralInfo staffdata={staffdata}/>}
          {tabs==="assignedclasses" && <AssignClassView classesassigned={classesassigned}/>}
          </section>
        </section>
      </section>
    </>
  );
};


