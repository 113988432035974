import React from "react";
import "./AcademicStaffInfo.css";
import DateSelector from "../../StudentDirectoryModule/Components/DateDropDown";
import { CustomDropdownWithCustomStyle } from "../../StudentDirectoryModule/Components/CustomDropdownWithCustomStyle";

export const AcademicStaffInfo = ({ data,setdata ,findmandatorychecks}) => {
  const handledatachange = (field, value) => {
    if (field==="phone_number") {
      if (value === "" || /^\d+$/.test(value)){

      }
        else return;
      
    }

      const updatedData = { ...data, [field]: value };
      setdata(updatedData);
    
 
   findmandatorychecks(updatedData);
    // console.log(await findmandatorychecks())
  };
  const role1=["Admin",
    "Accountant",
    "Receptionist",
    "Computer Operator",
    "Admission Manager",
    "Academic Director",
    "Librarian"];
    const role2=["Teacher","Academic Incharge"]
  return (
    <div >
      <div >
        <div className="detailsHeader">Academic Staff Info</div>
        <div className="detailsHeaderspan">Enter the details to proceed </div>
      </div>
      <div className="StudentInfoEditSilderdivbody">
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{display:'flex'}}>

            First Name
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
            </div>
            <input type="text" value={data.first_name} className="inputbox" placeholder="Chaitanya" onChange={(e)=>{handledatachange("first_name",e.target.value)}}/>
          </div>
          <div className="Details">
            Last Name
            <input type="text" className="inputbox" placeholder="Rao" value={data.last_name} onChange={(e)=>{handledatachange("last_name",e.target.value)}}/>
          </div>
        </div>
        
        <div className="Details">
          <label style={{ display: "flex" }}>
            Date of Birth
           
          </label>
          <DateSelector
           handledatachange={handledatachange}
           
            field={"dob"}
            width={"28.5rem"}
            value={data.dob}
          />
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>
            Contact Number
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
          <input
            type="text"
            className="inputbox"
            style={{ width: "28.5rem" }}
            placeholder="+91-932839283"
            value={data.phone_number} onChange={(e)=>{handledatachange("phone_number",e.target.value)}}
          />
        </div>
        <div className="Details">
          <div style={{ display: "flex" }}>
            Email ID
           
          </div>
          <input
            type="text"
            className="inputbox"
            style={{ width: "28.5rem" }}
            placeholder="Rao@grahith.com"
            value={data.email} onChange={(e)=>{handledatachange("email",e.target.value)}}

          />
        </div>
        <div className="Details">
              <label style={{ display: "flex" }}>
                Role
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <CustomDropdownWithCustomStyle
                defaultText={data.role?data.role:"Select Role"}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={data.staff_type==="admin_staff"?role1:role2}
                width={"28.5rem"}
                dropdownName={"role"}
                scrollenable={true}
                height="1.1rem"
              />
           
          </div>
        <div className="Details"  style={{paddingBottom:'5rem'}}>
          <label style={{ display: "flex" }}>
            Joining Date
           
          </label>
          <DateSelector
            handledatachange={handledatachange}
            field={"joining_date"}
            width={"28.5rem"}
            value={data.joining_date}
          />
        </div>

      
      </div>
    </div>
  );
};
