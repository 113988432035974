import React, { useEffect, useRef, useState } from "react";
import "./StaffDirMainPage.css";

import RouteHeader from "../FeeManagementModule/components/RouteHeader";
import { CreateHeader } from "../StudentDirectoryModule/Components/CreateHeader";
import { SortIcon } from "../../assets/svgs";
import { SearchBar } from "../StudentDirectoryModule/Components/SearchBar";
// import CustomDropdown from "../FeeManagementModule/components/Custom-Dropdown";
import Pagination from "../../home/Pagination";
import {
  formatRomanNumber,
  useOutsideClick,
  useToggle,
} from "../FeeManagementModule/FeeStructureModule/hooks";
import ActionsMenu from "../StudentDirectoryModule/Components/ActionsMenu";
import { NameAvatar } from "../FeeManagementModule/FeeCollectionModule/components/NameAvatar";
import Details from "../StudentDirectoryModule/Components/Deatails";
// import { AdminStaffDirComponent } from "./AdminStaffDirComponent";
// import { StaffProfileLayout } from "./components/StaffProfileLayout";
import { getStaffdetails } from "../../api/api";
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { CheckBoxDropDownWithSubItems } from "../../home/components/CheckBoxDropdown/CheckBoxDropDown";
import { DeletePopup } from "../StudentDirectoryModule/Components/DeletePopup";
import UserContext from "../../context/UserContext";
// import { IoInvertMode } from "react-icons/io5";
export const StaffDirMainPage = () => {
  const [selectedItemsCount, setselectedItemsCount] = useState(0);
  const dropdownRef = useRef(null);
  const [paginateddata, setpaginateddata] = useState([]);
  const [totalpages, settotalpages] = useState(0);
  const pageSize = 8;
  const { state: isOpen, handlers } = useToggle();
  useOutsideClick(dropdownRef, handlers.off);
  const [academicstaff, setacademicstaff] = useState(true);
  const [class_subject_dic,setclass_subject_dic]=useState({});
  const [curpage, setcurpage] = useState(1);
  const [loading, setloading] = useState(true);
  const [tableloading, settableloading] = useState(false);
  const [data, setdata] = useState([]);
  const [academicstaffdata,setacademicstaffdata]=useState([]);
  const { userInfo } = React.useContext(UserContext);
  const [adminstaffdata,setadminstaffdata]=useState([]);
  //eslint-disable-next-line
  const handlesearchdata = (page=1, data1 = data) => {
    console.log(page,data1)
    setcurpage(page);
    settotalpages(Math.ceil(data1.length / pageSize));
    console.log((page - 1) * pageSize, pageSize);
    setpaginateddata(
      data1.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
    );
  };
  const [checkeditem, setcheckeditem] = useState([]);
  const [deleteprofilepopup,setdeleteprofilepopup] =useState(false);
  //eslint-disable-next-line
  const handlePageChange = (page, data1 = data) => {
    setcurpage(page);
    console.log((page - 1) * pageSize, pageSize);
    setpaginateddata(
      data1.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
    );
  };
  const paginationhandling=(data)=>{
    if (data.length >= pageSize) {
      setpaginateddata(data.slice(0, pageSize));
    } else setpaginateddata(data);
    let items = {};
    data.forEach((item) => {
      items[item.user_id] = false;
    });
    items["all"] = false;
    console.log("items", items);
    setcheckeditem(items);

    settotalpages(Math.ceil(data.length / pageSize));
  }
 
  
  const changedata=()=>{
    try{
      setloading(true);
      if(academicstaff){
        if(academicstaffdata.length)
        paginationhandling(academicstaffdata);
      }
      else  { if(adminstaffdata.length)
        paginationhandling(adminstaffdata);}

    }
    finally{
setloading(false);
    }
  }
  useEffect(() => {
    changedata();
    // eslint-disable-next-line
  }, [academicstaff])
   const refreshdata = async (filters="") => {
    try {
      settableloading(true);
      setselectedItemsCount(0);
      let data;
      if(academicstaff)
    data = await getStaffdetails(`branch_id=${userInfo.branch_id}&staff_type=academic_staff&${filters}`);
  else data= await getStaffdetails(`branch_id=${userInfo.branch_id}&staff_type=admin_staff&${filters}`);
     setdata(data);

      await paginationhandling(data);
    } finally {
      settableloading(false);
    }
  };
  useEffect(() => {
    const loaddata = async () => {
      try{
      setloading(true)
      const data1 = await getStaffdetails(`branch_id=${userInfo.branch_id}&staff_type=academic_staff`);
      setdata(data1);
      setacademicstaffdata(data1);
      console.log(data1);
      if (data1.length >= pageSize) {
        setpaginateddata(data1.slice(0, pageSize));
      } else setpaginateddata(data1);
      let items = {};
      let sub_class={}
      data1.forEach((item) => {
        items[item.user_id] = false;
        if(item.teachings){
          for(let i=0;i<item.teachings.length;i++){
            // Check if the user_id key exists in sub_class
            if(!sub_class[item.user_id]){
             sub_class[item.user_id] = {subject:[], class:[]}; 
           }
           const className = `Class ${formatRomanNumber(item.teachings[i].class.split(" ")[1])}-${item.teachings[i].section}`;
           sub_class[item.user_id].subject.push(item.teachings[i].subject); 
           sub_class[item.user_id].class.push(className); 
       }
         
        }
      });
      setclass_subject_dic(sub_class)
      items["all"] = false;
      console.log("items", items);
      setcheckeditem(items);

      settotalpages(Math.ceil(data1.length / pageSize));
      const data2 = await getStaffdetails(`branch_id=${userInfo.branch_id}&staff_type=admin_staff`);
      setadminstaffdata(data2);
    }
    finally{
      setloading(false);
    }
    };
    if(userInfo.branch_id)
    loaddata();
  }, [userInfo.branch_id]);
  const handleCheckboxChange = (id) => {
    let temp = { ...checkeditem };
    temp[id] = !temp[id];
    const value = temp[id];

    if (id === "all") {
      Object.keys(temp).forEach((id1) => {
        temp[id1] = value;
      });

      // setcheckeditem(temp);
    }
    setselectedItemsCount(0);
    const filteredObj = Object.fromEntries(
      Object.entries(temp).filter(([key, value]) => {
        if (key !== "all" && value) {
          setselectedItemsCount((prevCount) => prevCount + 1);
          console.log(true);
        }
        return key !== "all";
      })
    );

    const alltrue = Object.values(filteredObj).every((value) => value === true);
    temp["all"] = alltrue;
    setcheckeditem(temp);
  };
  const [actionstaffid, setactionstaffid] = useState("no one");
  // const [bulkdataforbulkactions, setbulkdataforbulkactions] = useState([]);
  const [bulk, setbulk] = useState(false);
  const selectmenubulkitems = async (value) => {
    
    setbulk(true);
    const trueKeys = Object.entries(checkeditem)
      .filter(([key, value]) =>(key!=="all") && value === true) // Only keep entries with value true
      .map(([key]) => Number(key));
    console.log(trueKeys);
    if (value === "Delete") {
      setactionstaffid(trueKeys);
      setdeleteprofilepopup(true);
    } 
  };
  const selectmenuitems = (value, id) => {
  
    setbulk(false);
    if(value===3){
         setactionstaffid([id]);
      setdeleteprofilepopup(true);
    }
    // console.log(value, id);
    // setactionstaffid(id);
    // if (value === 1) {
    //   setassignclasspopup(true);
    // } else if (value === 2) {
    //   setmoveclasspopup(true);
    // } else {
    //   setactionstaffid([id]);
    //   setdeleteprofilepopup(true);
    // }
  };
  const handledatachange=(filterss,values)=>{
    console.log(values)
    let filters="";
    // if(academicstaff){
    //   filters+="staff_type=academic_staff&"
    // }
    // else{
    //   filters+="staff_type=admin_staff&"
    // }
  if(!values.includes("Profile status")){
   
    if(values.includes("Complete profiles")){
      filters=filters+"profile_status=Complete&"
    }
    else if(values.includes("Incomplete profiles")){
      filters=filters+"profile_status=Incomplete&"
    }
  }
  if(!values.includes("Class Status")){
  
    if(values.includes("Classes assigned")){
      filters=filters+"class_status=Assigned&"
    }

    else if(values.includes("Classes not assigned")){
      filters=filters+"class_status=NotAssigned&"
    }
  }
  console.log(values.includes("Class Teacher"));
  if(!values.includes("Role")){
    if(values.includes("Class Teacher")){
      filters=filters+"role=class_teacher&"
    } 
    else if(values.includes("Academic incharge")){
      filters=filters+"role=academic_incharge&"
    }
    else if(values.includes("Teacher")){
      filters=filters+"role=teacher&"
    }
  }
  console.log(filters)
  refreshdata(filters);
}
  return (


    <>
      { (
        <div className="StaffDir">
          <RouteHeader routeHeader="Staff Directory" routes={[]} search />
          <div className="reqmargins">
            <div className="switchbuttons">
              <div
                className={academicstaff?"switchbutton-active":"switchbutton"}
                onClick={() => {
                  setacademicstaff(true);
                }}
              >
                Academic Staff
              </div>
              <div
                className={!academicstaff?"switchbutton-active":"switchbutton"}
                style={{ border: "0" }}
                onClick={() => {
                  setacademicstaff(false);
                }}
              >
                Admin Staff
              </div>
            </div>
          </div>
         {academicstaff ?<CreateHeader
            text="Academic Staff Directory"
            subText="Edit / Manage the overall Academic Staff in the school"
            showButton
            buttontext="Add Academic Staff"
            incompleteprofileinfo={false}
            // buttonstate={setcreatemodalopen}
            navto={"/staffdirectory/addacademicstaff"}
          />: <CreateHeader
          text="Admin Staff Directory"
          subText="Edit / Manage the overall Admin Staff in the school"
          showButton
          buttontext="Add Staff"
          incompleteprofileinfo={false}
          // buttonstate={setcreatemodalopen}
            navto={"/staffdirectory/adminstaff"}
        />}
         {!loading && <div className="reqmargins" style={{ marginTop: "0" }}>
            <section
              className="dropdowns--section"
              style={{ paddingLeft: "-10rem" }}
            >
              <div className="bulk--actions__dropdown" ref={dropdownRef}>
                <button
                  className={`bulk-actions ${
                    selectedItemsCount > 1 && "bulk-actions--btn"
                  }`}
                  disabled={selectedItemsCount > 1 ? false : true}
                    onClick={handlers.toggle}
                >
                  Bulk actions
                  {selectedItemsCount > 1 && (
                    <span className="bulk-actions__count">
                      ({selectedItemsCount})
                    </span>
                  )}
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon--arrow"
                  >
                    <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"></path>
                  </svg>
                </button>

                 <div
                  className={`bulk__actions ${
                    isOpen ? "" : "bulk__actions--open"
                  }`}
                >
                  <div className="bulk__actions-group">
                  
                    <div
                      className="bulk__actions--btn"
                      style={{ width: "12rem", padding: "0" }}
                    >
                      <div
                        className="menu-item"
                        // onClick={() => {
                        //   selectmenuitems(3, id);
                        // }}
                        onClick={() => {
                          selectmenubulkitems("Delete");
                        }}
                      >
                        <div className="menu-icon">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.75 3.5H15.5V5H14V14.75C14 14.9489 13.921 15.1397 13.7803 15.2803C13.6397 15.421 13.4489 15.5 13.25 15.5H2.75C2.55109 15.5 2.36032 15.421 2.21967 15.2803C2.07902 15.1397 2 14.9489 2 14.75V5H0.5V3.5H4.25V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5H11C11.1989 0.5 11.3897 0.579018 11.5303 0.71967C11.671 0.860322 11.75 1.05109 11.75 1.25V3.5ZM12.5 5H3.5V14H12.5V5ZM9.0605 9.5L10.3865 10.826L9.326 11.8865L8 10.5605L6.674 11.8865L5.6135 10.826L6.9395 9.5L5.6135 8.174L6.674 7.1135L8 8.4395L9.326 7.1135L10.3865 8.174L9.0605 9.5ZM5.75 2V3.5H10.25V2H5.75Z"
                              fill="#0E121B"
                            />
                          </svg>
                        </div>{" "}
                        Delete Profile
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>

              <div className="filter__dropdowns">
                <CheckBoxDropDownWithSubItems
                 lefticon={<SortIcon />}
                 dropdownname={"Filter by"}
                 Items={{
                "Role":["Class Teacher","Academic incharge","Teacher"],
                  "Profile status":["Complete profiles","Incomplete profiles"],
                  "Class Status": ["Classes assigned", "Classes not assigned"],
                }}
                 handledatachange={handledatachange}
                />
                {/* <CustomDropdown
                  defaultText={"Sort By"}
                  icon={<FilterIcon />}
                  items={["low to high", "high to low"]}
                  width={"fit-content"}
                  dropdownName={"sort"}
                /> */}
                <div style={{ marginTop: "0.5rem" }}>
                  <SearchBar
                    data={data}
                    key1={"name_of_the_staff"}
                    handlesearchdata={handlesearchdata}
                    placeholder={"Search for Teacher"}
                  />
                </div>
              </div>
            </section>
            {!tableloading &&
              <div className="table-container" style={{ marginTop: "1rem" }}>
                <div className="table-container-header">
                  <div className="header-cell-container" style={{ flex: "2" }}>
                    <input
                      type="checkbox"
                      checked={checkeditem["all"]}
                      onChange={() => {
                        handleCheckboxChange("all");
                      }}
                    />
                    Name of Staff
                  </div>
                  {/* <div className="header-cell-container">Staff ID</div> */}
                  <div
                    className="header-cell-container"
                    style={{ flex: "1.5" }}
                  >
                    Role{academicstaff?"(s)":""}{" "}
                  </div>
                  <div
                    className="header-cell-container"
                    style={{ flex: "1.5" }}
                  >
                    Contact details
                  </div>
                  <div
                    className="header-cell-container"
                    style={{ flex: "1.5" }}
                  >
                   {academicstaff? "Assigned Classes/Subjects":"Responsibilities"}
                  </div>
                  <div className="header-cell-container">Actions</div>
                </div>
                <div className="table-container-body">
                  {paginateddata.length > 0 &&
                    // eslint-disable-next-line
                    paginateddata.map((item) => {
                      return (
                        <div className="row-container">
                          <div className="cell-container" style={{ flex: "2" }}>
                            <input
                              type="checkbox"
                              onChange={() => {
                                handleCheckboxChange(item.user_id);
                              }}
                              checked={checkeditem[item.user_id]}
                            />
                            <NameAvatar
                              name={item.name_of_the_staff}
                              image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.name_of_the_staff}`}
                              id={null}
                              navto={`/staffprofile/${item.user_id}/generalinfo`}
                            />
                          </div>
                          {/* <div className="cell-container">
                            <div className="StudentId">#null</div>
                          </div> */}
                          <div
                            className="cell-container"
                            style={{ flex: "1.5" }}
                          >
                            {
                              item.responsibility?<>
                                 <div className="borderbox">{item.responsibility[0].role}</div>
                                {item.responsibility.length>1 &&
                                <NavLink
                                to={`/staffprofile/${item.user_id}/generalinfo`}
                               style={{color:'rgba(51, 92, 255, 1)',cursor:'pointer'}}
                              >
                             
                                  +{item.responsibility.length-1}
                               
                              </NavLink>
                                }
                           
                              </>
                              
                              :  <div className="borderbox">{item.role}</div>
                              
                            }

                          </div>
                          <div
                            className="cell-container"
                            style={{ flex: "1.5" }}
                          >
                            {" "}
                            <Details
                              Method={item.phone_number}
                              ID={item.email}
                            />
                          </div>
                          <div
                            className="cell-container"
                            style={{ flex: "1.5" }}
                          >
                            {/* <Details Method={"Class VI-A"} ID={"Maths"} /> class_subject_dic[item.user_id].class[0] */}
                            {/* <div className={`student-class`}>
                              <span className="student-class__class">{class_subject_dic[item.user_id]?class_subject_dic[item.user_id].class[0]:'-'}</span>
                              <span className="student-class__programme">{class_subject_dic[item.user_id]?class_subject_dic[item.user_id].subject[0]:'-'}</span>
                            </div> */}
                            {
                              item.teachings?<>
                                 <div className={`student-class`}>
                                  <div>

                              <span className="student-class__class">{class_subject_dic[item.user_id]?class_subject_dic[item.user_id].class[0]:'-'}</span>
                               {class_subject_dic[item.user_id].class.length>1 &&
                                <NavLink
                                to={`/staffprofile/${item.user_id}/assignedclasses`}
                               style={{color:'rgba(51, 92, 255, 1)',cursor:'pointer'}}
                              >
                             
                                  +{class_subject_dic[item.user_id].class.length-1}
                               
                              </NavLink>
                                }
                                  </div>
                                  <div>

                             {class_subject_dic[item.user_id].subject.length>=1 && <span className="student-class__programme">{class_subject_dic[item.user_id]?class_subject_dic[item.user_id].subject[0]:'-'}</span>}
                             {class_subject_dic[item.user_id].subject.length>1 && <span className="student-class__programme">, {class_subject_dic[item.user_id]?class_subject_dic[item.user_id].subject[1]:'-'}</span>}
                              {class_subject_dic[item.user_id].subject.length>2 &&
                                <NavLink
                                to={`/staffprofile/${item.user_id}/assignedclasses`}
                               style={{color:'rgba(51, 92, 255, 1)',cursor:'pointer'}}
                              >
                             
                                  +{class_subject_dic[item.user_id].subject.length-1}
                               
                              </NavLink>
                                }
                                  </div>
                            </div>
                               
                           
                              </>
                              
                              : '-'
                              
                            }
                          </div>

                          <div className="cell-container">
                            <ActionsMenu dir="Staff" id={item.user_id} selectmenuitems={selectmenuitems} />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            }
            {!tableloading && <Pagination
              currentPage={curpage}
              totalPages={totalpages}

              pageSize={pageSize}
              onPageChange={handlePageChange}
            />}
          </div>}
          {(loading  || tableloading) && (
            <div className="StudentDirectoryBodyTable">
              <div className="table-container">
                <div className="table-container-header">
                  <div className="cell-container" style={{ flex: "2" }}>
                    <Skeleton width={"10rem"} height={"100%"} />
                  </div>
                  <div className="cell-container">
                    <Skeleton width={"5rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"8rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  {/* <div className="cell-container">
                    <Skeleton width={"2rem"} height={"100%"} />
                  </div> */}
                  <div className="cell-container">
                    <Skeleton width={"3rem"} height={"100%"} />
                  </div>
                </div>

                <div className="table-container-body">
                  {Array.from({ length: 8 }, (_, index) => (
                    <div className="row-container">
                      <div className="cell-container" style={{ flex: "2" }}>
                        <Skeleton width={"10rem"} height={"100%"} />
                      </div>
                      <div className="cell-container">
                        <Skeleton width={"5rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"7rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"8rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"7rem"} height={"100%"} />
                      </div>
                      {/* <div className="cell-container">
                        <Skeleton width={"2rem"} height={"100%"} />
                      </div> */}
                      <div className="cell-container">
                        <Skeleton width={"3rem"} height={"100%"} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {deleteprofilepopup && (
        <DeletePopup
          refreshdata={refreshdata}
          actionstudentids={actionstaffid}
          setpopup={setdeleteprofilepopup}
          dir="staff"
          bulk={bulk}
        />
      )}
      {/* {!academicstaff && (
        <AdminStaffDirComponent setacademicstaff={setacademicstaff} />
      )} */}
    </>
  );
};
