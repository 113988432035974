import React from 'react'
import { NameAvatar } from "../../FeeManagementModule/FeeCollectionModule/components/NameAvatar";
import { findnull } from '../../../home/components/Findnull';
import { formatDate } from '../../FeeManagementModule/FeeStructureModule/hooks';

export const StaffPreviewandFinalise = ({data,setdata}) => {
  return (
    <div>
      <NameAvatar
        name={findnull(data.first_name)}
        image={`https://api.dicebear.com/8.x/initials/svg?seed=${findnull(data.first_name)}`}
        // id={"123456"}
        id={null}
      />
      <div className="line"></div>

      <div className="StudentGeneralInfodivCard" style={{paddingBottom:'5rem'}}>
        <div className="header">Staff info</div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>FIRST NAME</span>
             {findnull(data.first_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>LAST NAME</span>
              {findnull(data.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>DATE OF BIRTH</span>
              {findnull(data.dob)!=='-'?formatDate(data.dob):'-'}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>CONTACT NUMBER</span>
              {findnull(data.phone_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMAIL ID</span>
              {findnull(data.email)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>JOINING DATE</span>
              {findnull(data.joining_date)!=='-'?formatDate(data.joining_date):'-'}
            </div>
          </div>
         
        </div>
        <div className="header">Personal info</div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>GENDER</span>
             {findnull(data.gender)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>BLOOD GROUP</span>
              {findnull(data.blood_group)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span> Address</span>
              { findnull(data.address)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>NAME OF EMERGENCY CONTACT</span>
             {findnull(data.name_of_emergency_contact)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMERGENCY CONTACT NUMBER</span>
              {findnull(data.emergency_contact)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>RELATION</span>
              {findnull(data.relation_of_emergency_contact)}
            </div>
          </div>
        </div>
        <div className="header">PROFESSIONAL INFO</div>
        <div
          className="StudentGeneralInfodivCardItem"
          style={{ marginBottom: "0.7rem" }}
        >
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>YEARS OF EXPERIENCE</span>
              {findnull(data.years_of_experience)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMPLOYEMENT TYPE</span>
              {findnull(data.contract_type)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>HIGHESHT QUALIFICATION</span>
             {findnull(data.highest_education)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>SUBJECT EXPERTISE</span>
            {findnull(data.primary_subject)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>BACKGROUND VERIFICATION</span>
             {findnull(data.background_check)}
            </div>
            
          </div>
        </div>
      </div>
    
    </div>
  );
}
