import React, { useState } from "react";

export const useFilled = (initialValue = 0) => {
  const [filled, setFilled] = useState(initialValue);
  return [filled, setFilled];
};

export const usePopup = (initialValue = false) => {
  const [isPopupOpen, setIsPopupOpen] = useState(initialValue);
  return [isPopupOpen, setIsPopupOpen];
};

export const useClasses = (
  initialClasses = ["Tution fee", "Fee 2", "Fee 3", "Fee 5", "Fee 6"]
) => {
  const [classes, setClasses] = useState(initialClasses);
  return [classes, setClasses];
};

export const useCategories = (
  initialClasses = [
    {
      id: 1,
      category: "Tution Fees",
      organization_id: 1,
      created_at: "2024-05-31T10:35:24.000Z",
      updated_at: "2024-05-31T10:35:24.000Z",
    },
    {
      id: 2,
      category: "Transportation Fee",
      organization_id: 1,
      created_at: "2024-05-31T11:39:20.000Z",
      updated_at: "2024-06-03T18:49:57.000Z",
    },
    {
      id: 3,
      category: "Library Fee",
      organization_id: 1,
      created_at: "2024-06-07T10:29:23.000Z",
      updated_at: "2024-06-07T10:29:23.000Z",
    },
  ]
) => {
  const [classes, setClasses] = useState(initialClasses);
  return [classes, setClasses];
};

export const useDropdown = (initialValue = false) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(initialValue);
  return [isDropdownOpen, setIsDropdownOpen];
};

export const useCheckedItems = (initialClasses) => {
  const [moreInfo, setMoreInfo] = useState(
    new Array(initialClasses.length + 1).fill(false)
  );
  return [moreInfo, setMoreInfo];
};

export const useConcessionBox = (initialValue = false) => {
  const [concessionBox, setConcessionBox] = useState(initialValue);
  return [concessionBox, setConcessionBox];
};

export const useMoreInfo = (initialClasses) => {
  const [moreInfo, setMoreInfo] = useState(initialClasses);
  return [moreInfo, setMoreInfo];
};

export const useNewFeeType = (initialValue = "") => {
  const [newFeeType, setNewFeeType] = useState(initialValue);
  return [newFeeType, setNewFeeType];
};

export const useName = (initialValue = "") => {
  const [name, setName] = useState(initialValue);
  return [name, setName];
};

export const useId = (initialValue = "") => {
  const [id, setId] = useState(initialValue);
  return [id, setId];
};

export const useSelectedClasses = (initialValue = []) => {
  const [selectedClasses, setSelectedClasses] = useState(initialValue);
  return [selectedClasses, setSelectedClasses];
};

//   export const useDropdown = (initialValue = false) => {
//     const [isDropdownOpen, setIsDropdownOpen] = useState(initialValue);
//     return [isDropdownOpen, setIsDropdownOpen];
//   };

export const useDropdown2 = (initialValue = false) => {
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(initialValue);
  return [isDropdownOpen2, setIsDropdownOpen2];
};

export const useChecked = (initialValue = false) => {
  const [checked, setChecked] = useState(initialValue);
  return [checked, setChecked];
};

export const useOption = (initialValue = "Select an option") => {
  const [option, setOption] = useState(initialValue);
  return [option, setOption];
};

export const useSubclasses = (
  initialValue = {
    VI: {
      A: true,
      B: true,
      C: true,
    },
    VII: {
      A: false,
      B: false,
      C: false,
    },
  }
) => {
  const [subclasses, setSubclasses] = useState(initialValue);
  return [subclasses, setSubclasses];
};

export const useStartDate = (initialValue = null) => {
  const [startDate, setStartDate] = useState(initialValue);
  return [startDate, setStartDate];
};

export const useApplyDate = (initialValue = false) => {
  const [applydate, setApplyDate] = useState(initialValue);
  return [applydate, setApplyDate];
};

export const useOpenCalendar = (initialValue = false) => {
  const [opencalendar, setOpenCalendar] = useState(initialValue);
  return [opencalendar, setOpenCalendar];
};

export const useStartDate2 = (initialValue = null) => {
  const [startDate2, setStartDate2] = useState(initialValue);
  return [startDate2, setStartDate2];
};

export const useApplyDate2 = (initialValue = false) => {
  const [applydate2, setApplyDate2] = useState(initialValue);
  return [applydate2, setApplyDate2];
};

export const useOpenCalendar2 = (initialValue = false) => {
  const [opencalendar2, setOpenCalendar2] = useState(initialValue);
  return [opencalendar2, setOpenCalendar2];
};

export const useMoreOptionDropdown = (initialValue = false) => {
  const [moreoptiondropdown, setMoreOptionDropdown] = useState(initialValue);
  return [moreoptiondropdown, setMoreOptionDropdown];
};

export const useConcessionData = (initialValue = 0) => {
  const [concessiondata, setconcessiondata] = useState(initialValue);
  return [concessiondata, setconcessiondata];
};

export const useMoreRow = (initialValue = true) => {
  const [addnewcategoryrow, setaddnewcategoryrow] = useState(initialValue);
  return [addnewcategoryrow, setaddnewcategoryrow];
};
export const useFirstRender = (initialValue = true) => {
  const [firstRender, setFirstRender] = useState(initialValue);
  return [firstRender, setFirstRender];
};

export const useIntialiseData = (initialValue = []) => {
  const [data, setdata] = useState(initialValue);
  return [data, setdata];
};
export const useForm = (initialState) => {
  const [data, setData] = React.useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return [data, handleChange];
};

export const useFormErrors = (initialState) => {
  const [errors, setErrors] = React.useState(initialState);

  const setFieldError = (field, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: message,
    }));
  };

  const resetErrors = () => {
    setErrors(initialState);
  };

  return [errors, setFieldError, resetErrors];
};

export const useOutsideClick = (ref, callback) => {
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

export const useSearch = (initialItems, searchKey) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredItems = React.useMemo(() => {
    if (initialItems)
      return initialItems.filter((item) =>
        item[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
      );
  }, [initialItems, searchKey, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return {
    searchTerm,
    filteredItems,
    handleSearchChange,
  };
};

export const useToggle = (initialState = false) => {
  const [state, setState] = React.useState(initialState);

  const handlers = React.useMemo(
    () => ({
      on: () => {
        setState(true);
      },
      off: () => {
        setState(false);
      },
      toggle: () => {
        setState((s) => !s);
      },
    }),
    []
  );

  return { state, handlers };
};

export const useSelectState = (initialValue) => {
  const [value, setValue] = React.useState(initialValue);

  const handleSelectChange = (newValue) => {
    setValue(newValue);
  };

  return { value, handleSelectChange };
};

export const formatDate = (date) => {
  if(date===null || date==="0000-00-00")return "-";
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const formatDateWithTime = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatNumber = (num) => {
  const rupeeSymbol = "₹";
  if (num >= 1000000000) {
    return rupeeSymbol + (num / 1000000000).toFixed(2) + " B";
  } else if (num >= 10000000) {
    return rupeeSymbol + (num / 10000000).toFixed(2) + " Cr";
  } else if (num >= 100000) {
    return rupeeSymbol + (num / 100000).toFixed(2) + " lac";
  } else if (num >= 1000) {
    return rupeeSymbol + (num / 1000).toFixed(2) + " k";
  } else {
    return rupeeSymbol + num;
  }
};

export const formatPrice = (price) => {
  return price.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
};
export const formatPriceRs = (price) => {
  return price.toLocaleString({
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
};
export const formatPriceWithExponential = (price) => {
  return price.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
};

export const formatRomanNumber = (number) => {
  const romanNumbers = {
    1: "I",
    2: "II",
    3: "III",
    4: "IV",
    5: "V",
    6: "VI",
    7: "VII",
    8: "VIII",
    9: "IX",
    10: "X",
  };
  const intNumber = parseInt(number, 10);

  if (Number.isInteger(intNumber)) {
    return romanNumbers[intNumber] || number; 
  } else {
    return number; 
  }
};
