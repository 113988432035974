import React, { useState, useEffect, useRef } from "react";
import "./FeeCollectionTable.css";
import { NameAvatar } from "./components/NameAvatar";
import { StudentClass } from "./components/StudentClass";
import { DueAmount } from "./components/DueAmount";
import { TermsPaid } from "./components/TermsPaid";
import CollectFee from "./components/CollectFee";
import RequestRemindFees from "./components/RequestRemindFee";
import BulkRemindFees from "./components/BulkRemindFees";
import RemindFeesSheet from "./components/RemindFeesSheet";
import { useOutsideClick, useToggle } from "../FeeStructureModule/hooks";
import { RemindFees, RequestFees } from "../../../assets/RequestRemindFees";
import { DueDate } from "./components/DueDate";
import CustomDropdown from "../components/Custom-Dropdown";
import { FilterIcon, SortIcon } from "../../../assets/svgs";
// import loading from "../../../assets/loading.gif";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableHeader,
//   TableRow,
// } from "../components/Table";
import Skeleton from "react-loading-skeleton";

const FeeCollectionTable = ({
  items,
  activeLink,
  updatedFeeData,
  isLoading,
  filterTerm
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [openActionsIndex, setOpenActionsIndex] = useState(null);
  const dropdownRef = useRef(null);
  const { state: isOpen, handlers } = useToggle();
  const { state: isSheetOpen, handlers: sheetHandlers } = useToggle();
  const { state: isRequestSheetOpen, handlers: RequestSheetHandlers } =
    useToggle();
  useOutsideClick(dropdownRef, handlers.off);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [method, setMethod] = useState("");

  useEffect(() => {
    setCheckedItems([]);
  }, [items]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    if (newSelectAll) {
      setCheckedItems(items);
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckboxChange = (item) => {
    let updatedCheckedItems;
    if (checkedItems.includes(item)) {
      updatedCheckedItems = checkedItems.filter(
        (checkedItem) => checkedItem !== item
      );
    } else {
      updatedCheckedItems = [...checkedItems, item];
    }
    setCheckedItems(updatedCheckedItems);
    setSelectAll(updatedCheckedItems.length === items.length);
  };

  const handleActionsClick = (index) => {
    setOpenActionsIndex(openActionsIndex === index ? null : index);
    setSelectedRowIndex(index);
  };

  const selectedItemsCount = checkedItems.length;

  return (
    <>
      <section className="dropdowns--section">
        <div className="bulk--actions__dropdown" ref={dropdownRef}>
          <button
            className={`bulk-actions ${
              selectedItemsCount > 1 && "bulk-actions--btn"
            }`}
            disabled={selectedItemsCount > 1 ? false : true}
            onClick={handlers.toggle}
          >
            Bulk actions
            {selectedItemsCount > 1 && (
              <span className="bulk-actions__count">
                ({selectedItemsCount})
              </span>
            )}
            <svg
              width="25"
              height="25"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon--arrow"
            >
              <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"></path>
            </svg>
          </button>
          <div
            className={`bulk__actions ${isOpen ? "" : "bulk__actions--open"}`}
          >
            <div className="bulk__actions-group">
              {activeLink !== "completed" && (
                <button
                  className="bulk__actions--btn"
                  onClick={() => {
                    sheetHandlers.toggle();
                    handlers.off();
                  }}
                >
                  <RemindFees />
                  Bulk Remind for Fees
                </button>
              )}
              {activeLink === "overdue" && (
                <button className="bulk__actions--btn">
                  <RequestFees />
                  Bulk Request for Fees
                </button>
              )}
              {activeLink === "completed" && (
                <button className="bulk__actions--btn bulk__actions--completed">
                  Bulk download invoice
                </button>
              )}
            </div>
          </div>
        </div>
        {activeLink !== "completed" && (
          <div className="filter__dropdowns">
            <CustomDropdown
              defaultText={"Filter Due amount"}
              icon={<SortIcon />}
              items={[
                "Less than ₹10,000",
                "₹10,000 - ₹20,000",
                "₹20,000 - ₹30,000",
                "₹30,000 - ₹40,000",
                "More than ₹40,000",
              ]}
              dropdownName={"filter"}
            />
            <CustomDropdown
              defaultText={"low to high"}
              icon={<FilterIcon />}
              items={["low to high", "high to low"]}
              width={"fit-content"}
              dropdownName={"sort"}
            />
          </div>
        )}
      </section>
      {isSheetOpen && (
        <BulkRemindFees
          isSheetOpen={isSheetOpen}
          handlers={sheetHandlers}
          items={checkedItems}
        />
      )}
      {/* {isLoading ? (
        <Table className="fee-collection__table">
          <TableHeader className="fee-collection__table-header">
            <TableRow className="fee-collection__table-row">
              <TableHead>
                <input
                  type="checkbox"
                  className="fee-collection__table-checkbox-first"
                />
              </TableHead>
              <TableHead className="fee-collection__table-heading">
                Student Name{" "}
              </TableHead>
              <TableHead className="fee-collection__table-heading">
                Student Class
              </TableHead>
              {(activeLink === "pending" || activeLink === "overdue") && (
                <TableHead className="fee-collection__table-heading">
                  Amount Due
                </TableHead>
              )}
              <TableHead className="fee-collection__table-heading">
                Due Date
              </TableHead>
              <TableHead className="fee-collection__table-heading">
                Terms Paid
              </TableHead>
              <TableHead className="fee-collection__table-heading">
                Actions
              </TableHead>
            </TableRow>
          </TableHeader>
          <img
            className="LoadImage"
            src={loading}
            alt="LoadImage"
            style={{
              width: "40%",
              height: "40%",
              marginLeft: "340%",
              padding: "100%",
            }}
          />
        </Table>
      ) : (
        <>
          <Table className="fee-collection__table">
            <TableHeader className="fee-collection__table-header">
              <TableRow className="fee-collection__table-row">
                <TableHead>
                  <input
                    type="checkbox"
                    className="fee-collection__table-checkbox-first"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </TableHead>
                <TableHead className="fee-collection__table-heading">
                  Student Name{" "}
                </TableHead>
                <TableHead className="fee-collection__table-heading">
                  Student Class
                </TableHead>
                {(activeLink === "pending" || activeLink === "overdue") && (
                  <TableHead className="fee-collection__table-heading">
                    Amount Due
                  </TableHead>
                )}
                <TableHead className="fee-collection__table-heading">
                  Due Date
                </TableHead>
                <TableHead className="fee-collection__table-heading">
                  Terms Paid
                </TableHead>
                <TableHead className="fee-collection__table-heading">
                  Actions
                </TableHead>
                {(activeLink === "pending" || activeLink === "overdue") &&
                  (selectedItemsCount === 0 || selectedItemsCount === 1) && (
                    <TableHead className="fee-collection__table-heading"></TableHead>
                  )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index} className="fee-collection__table-row">
                  <TableCell>
                    <input
                      type="checkbox"
                      className="fee-collection__table-checkbox"
                      checked={checkedItems.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </TableCell>
                  <TableCell className="fee-collection__table-data">
                    <NameAvatar
                      name={item.full_name}
                      image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                      id={item.student_id}
                      user_id={item.user_id}
                    />
                  </TableCell>
                  <TableCell className="">
                    <StudentClass
                      class_Name={item.class}
                      section_name={item.section}
                    />
                  </TableCell>
                  {(activeLink === "pending" || activeLink === "overdue") && (
                    <TableCell className="fee-collection__table-data">
                      <DueAmount
                        amount={item.total_amount_paid}
                        total={item.total_term_fee}
                        concession={item.total_term_concession}
                      />
                    </TableCell>
                  )}
                  <TableCell className="fee-collection__table-data">
                    <DueDate date={item.next_due_date} />
                  </TableCell>
                  <TableCell className="fee-collection__table-data">
                    <TermsPaid
                      termsPaid={Number(item.terms_completed)}
                      totalTerms={item.terms}
                    />
                  </TableCell>
                  <TableCell className="fee-collection__table-data">
                    {activeLink === "completed" ? (
                      <button className="collect-fee__btn">Download</button>
                    ) : (
                      <CollectFee item={item} updatedFeeData={updatedFeeData} />
                    )}
                  </TableCell>
                  {(activeLink === "pending" || activeLink === "overdue") &&
                    (selectedItemsCount === 0 || selectedItemsCount === 1) && (
                      <TableCell className="fee-collection__table-data">
                        <RequestRemindFees
                          isOpen={openActionsIndex === index}
                          onClick={() => handleActionsClick(index)}
                          handlers={RequestSheetHandlers}
                          setMethod={setMethod}
                        />
                      </TableCell>
                    )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isRequestSheetOpen && (
            <RemindFeesSheet
              isSheetOpen={isRequestSheetOpen}
              handlers={RequestSheetHandlers}
              items={selectedRowIndex !== null ? [items[selectedRowIndex]] : []}
              method={method}
            />
          )}
        </>
      )} */}
       {isLoading && (
        <div className="StudentDirectoryBodyTable">
          <div className="table-container">
            <div className="table-container-header">
              <div className="cell-container" style={{ flex: "2" }}>
                <Skeleton width={"10rem"} height={"100%"} />
              </div>
              <div className="cell-container">
                <Skeleton width={"5rem"} height={"100%"} />
              </div>
              <div className="cell-container" style={{ flex: "1.5" }}>
                <Skeleton width={"7rem"} height={"100%"} />
              </div>
              <div className="cell-container" style={{ flex: "1.5" }}>
                <Skeleton width={"8rem"} height={"100%"} />
              </div>
              <div className="cell-container" style={{ flex: "1.5" }}>
                <Skeleton width={"7rem"} height={"100%"} />
              </div>
              {/* <div className="cell-container">
                    <Skeleton width={"2rem"} height={"100%"} />
                  </div> */}
              <div className="cell-container">
                <Skeleton width={"3rem"} height={"100%"} />
              </div>
            </div>

            <div className="table-container-body">
              {Array.from({ length: 8 }, (_, index) => (
                <div className="row-container">
                  <div className="cell-container" style={{ flex: "2" }}>
                    <Skeleton width={"10rem"} height={"100%"} />
                  </div>
                  <div className="cell-container">
                    <Skeleton width={"5rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"8rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  {/* <div className="cell-container">
                        <Skeleton width={"2rem"} height={"100%"} />
                      </div> */}
                  <div className="cell-container">
                    <Skeleton width={"3rem"} height={"100%"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {!isLoading && <div className="table-container">
        <div
          className="table-container-header"
          style={{ display: "flex", width: "100%" }}
        >
          <div
            className="header-cell-container"
            style={{
              flex: "2.5",
              display: "flex",
              gap: "1.5rem",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            Student Name
          </div>
          <div
            className="header-cell-container"
            style={{ flex: "1.3", textAlign: "center" }}
          >
            Student Class
          </div>
          {(activeLink === "pending" || activeLink === "overdue") && (
            <div
              className="header-cell-container"
              style={{ flex: "1.3", textAlign: "center" }}
            >
              Amount Due
            </div>
          )}
          <div
            className="header-cell-container"
            style={{ flex: "1.5", textAlign: "center" }}
          >
            Due Date
          </div>
          <div
            className="header-cell-container"
            style={{ flex: "1.2", textAlign: "center" }}
          >
            Terms Paid
          </div>
          <div
            className="header-cell-container"
            style={{ flex: "2", justifyContent: "center", textAlign: "center" }}
          >
            Actions
          </div>
        </div>

        <div
          className="table-container-body"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className="row-container"
              style={{ display: "flex", width: "100%" }}
            >
              <div
                className="cell-container"
                style={{
                  flex: "2.5",
                  display: "flex",
                  gap: "1.5rem",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={checkedItems.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                />
                <NameAvatar
                  name={item.full_name}
                  image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                  id={item.student_id}
                  user_id={item.user_id}
                />
              </div>
              <div
                className="cell-container"
                style={{ flex: "1.3", textAlign: "center" }}
              >
                <StudentClass
                  class_Name={item.class}
                  section_name={item.section}
                />
              </div>
              {(activeLink === "pending" || activeLink === "overdue") && (
                <div
                  className="cell-container"
                  style={{ flex: "1.3", textAlign: "center" }}
                >
                  <DueAmount
                    amount={item.total_amount_paid}
                    total={item.total_term_fee}
                    concession={item.total_term_concession}
                  />
                </div>
              )}
              <div
                className="cell-container"
                style={{ flex: "1.5", textAlign: "center" }}
              >
                <DueDate date={item.next_due_date} />
              </div>
              <div
                className="cell-container"
                style={{ flex: "1.2", textAlign: "center" }}
              >
                <TermsPaid
                  termsPaid={Number(item.terms_completed)}
                  totalTerms={item.terms}
                />
              </div>
              <div
                className="cell-container"
                style={{
                  flex: "2",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {activeLink === "completed" ? (
                  <button className="collect-fee__btn">Download</button>
                ) : (
                  <CollectFee filterTerm={filterTerm} item={item} updatedFeeData={updatedFeeData} />
                )}
                {(activeLink === "pending" || activeLink === "overdue") &&
                  (selectedItemsCount === 0 || selectedItemsCount === 1) && (
                    <RequestRemindFees
                      isOpen={openActionsIndex === index}
                      onClick={() => handleActionsClick(index)}
                      handlers={RequestSheetHandlers}
                      setMethod={setMethod}
                    />
                  )}
              </div>
            </div>
          ))}
        </div>
        {isRequestSheetOpen && (
          <RemindFeesSheet
            isSheetOpen={isRequestSheetOpen}
            handlers={RequestSheetHandlers}
            items={selectedRowIndex !== null ? [items[selectedRowIndex]] : []}
            method={method}
          />
        )}
      </div>}
    </>
  );
};

export default FeeCollectionTable;
