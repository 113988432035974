import React from 'react'

export const AssignClassView = ({classesassigned}) => {
  return (
    <div style={{paddingLeft:'0.5rem',width:'98%'}}>
         <div className="table-container" style={{ marginTop: "1rem" }}>
                <div className="table-container-header">
                  <div className="header-cell-container" >
                    
                   Subject Name
                  </div>
                  {/* <div className="header-cell-container">Staff ID</div> */}
                  <div
                    className="header-cell-container"
                    style={{ flex: "3" }}
                  >
                    Class{" "}
                  </div>
                 
                
                  <div className="header-cell-container">Actions</div>
                </div>
                <div className="table-container-body">
               { classesassigned.map(item => {
                  return (
                  <div className="row-container">
                    <div className="cell-container">{item.subject}</div>
                    <div className="cell-container"  style={{ flex: "3" }}>
                        <div className="cylinder-shape">{item.class}</div>
                        {/* <div className="cylinder-shape">Ass</div>
                        <div className="cylinder-shape">Ass</div> */}
                    </div>
                    <div className="cell-container" style={{cursor:'not-allowed'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"></path></svg></div>
                  </div>)})
                  }
                </div>
              </div>
    </div>
  )
}
