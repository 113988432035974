import "./CollectFee.css";
import React from "react";
import PrintPDF from "./Receipt.js";
import {
  formatDate,
  formatPrice,
  useToggle,
  formatPriceWithExponential,
} from "../../FeeStructureModule/hooks.js";
import CollectFeeTable from "./CollectFeeTable.js";
import { Sheet } from "../../components/Sheet.js";
import Invoice from "../../../../assets/invoice.js";
import PropTypes from "prop-types";
import { getReceiptDetails, getUserFeePayment } from "../../../../api/api.js";

const CollectFee = ({filterTerm=null, item, updatedFeeData=()=>{},handlerefresh=()=>{} }) => {
 
  const { state: isOpen, handlers } = useToggle();
  const [termFeeData, setTermFeeData] = React.useState([]);
  const termsPaid = item.terms_completed;
  const totalTerms = item.terms;
  const [isSecondPage, setIsSecondPage] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const progressDegree = (termsPaid / totalTerms) * 360;
  const [receiptId, setReceiptId] = React.useState(0);
  const [receiptData, setReceiptData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dataArgs, setDataArgs] = React.useState(null);
  const [fathername,setfathername]= React.useState("-")
  const updateTableData = (arg1, arg2) => {
    setDataArgs([arg1, arg2]);
    if (!isOpen) {
      updatedFeeData(arg1, arg2);
    }
  };
  React.useEffect(() => {
    if (!isOpen) {
      if (dataArgs) {
        updatedFeeData(dataArgs[0], dataArgs[1]);
      }
    }
    // eslint-disable-next-line
  }, [isOpen, dataArgs]);

  const handleClick = () => {
    handlers.toggle();
    getUserFeePayment(item.user_id, item.fee_structure_id).then((res) => {
      setTermFeeData(res.response.fee_payments);
      setfathername(res.response.father_name)
    });
  };

  const handlePrintPDF = async (receiptData) => {
    setLoading(true);
    try {
      await PrintPDF(receiptData);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isSecondPage && receiptId !== undefined && receiptId !== null) {
      const fetchReceiptDetails = async () => {
        setIsLoading(true);
        try {
          const res = await getReceiptDetails(receiptId);
          setReceiptData(res.response);
        } catch (error) {
          console.error("Error fetching receipt details:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchReceiptDetails();
    }
  }, [isSecondPage, receiptId]);

  return (
    <div className="collect-fee">
      <button className="collect-fee__btn" onClick={handleClick}>
        Collect Fee
      </button>
      <Sheet
        width={47}
        headerTitle={
          isSecondPage ? "Validate Payment Information" : "Collect Fee"
        }
        isSheetOpen={isOpen}
        handlers={{
          ...handlers,
          off: () => {
            setIsSecondPage(false);
            handlers.off();
          },
        }}
      >
        <section className="collect-fee__section--content">
          <div className="collect-fee__content">
            <img
              className="collect-fee__avatar"
              src="https://api.dicebear.com/8.x/big-smile/svg"
              alt="Name of Student"
            />
            <div className="collect-fee__details">
              <div className="collect-fee__student-info">
                <span className="collect-fee__student-name">
                  {item.full_name}
                </span>
                {item.student_id !== null && (
                  <span className="collect-fee__student-id">
                    # {item.student_id}
                  </span>
                )}
              </div>
              <span className="collect-fee__student-class">
                {item.class}-{item.section}
              </span>
              <span className="collect-fee__student-class">
              <span style={{color:'black'}}>
              {"Father's Name : "}
                </span>{fathername}
            </span>
            </div>
          </div>
          {isSecondPage ? (
            <React.Fragment>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <div className="validation__info">
                    <p className="collection-amount__text">Collection Amount</p>
                    <div className="valid-fee__price--container">
                      <span className="valid-fee__price">
                        {formatPrice(Number(receiptData.total_amount_paid))}
                      </span>
                      <div className="valid-fee__term">
                        Terms{" "}
                        {/* {receiptData.terms_paid
                          ? receiptData.terms_paid
                              .map((termPaid) => termPaid.term)
                              .join(", ")
                          : ""} */}
                      </div>
                    </div>
                  </div>
                  <div className="validation__container">
                    <>
                      <p className="validation__heading">Name of Father</p>
                      <p className="validation__text father__name">
                        {receiptData.father_name === null ||
                        receiptData.father_name === ""
                          ? "NA"
                          : receiptData.father_name}
                      </p>
                      <span className="divider"></span>
                    </>

                    <>
                      <p className="validation__heading">Payment Method</p>
                      <p className="validation__text payment---method">
                        {paymentMethod}
                      </p>
                      <span className="divider"></span>
                    </>
                    {receiptData.payment_method !== "cash" && (
                      <>
                        <p className="validation__heading">UPI APP</p>
                        <p className="validation__text payment---method">
                          {receiptData.payment_method}
                        </p>
                        <span className="divider"></span>
                      </>
                    )}
                    <>
                      {receiptData.payment_method === "cash" ? (
                        <p className="validation__heading">Reference ID</p>
                      ) : (
                        <p className="validation__heading">UTR Number</p>
                      )}
                      <p className="validation__text">
                        {receiptData.reference_id}
                      </p>
                      <span className="divider"></span>
                    </>
                    <>
                      <p className="validation__heading">Date & Time</p>
                      <p className="validation__text">
                        {formatDate(receiptData.receipt_date)} at{" "}
                        {receiptData.receipt_time}
                      </p>
                      <span className="divider"></span>
                    </>
                  </div>
                  <div className="validation__pdf">
                    <p className="validation__pdf--heading">Receipt</p>
                    <div className="validation__pdf--invoice">
                      <Invoice />
                      <div className="validation__pdf--info-container">
                        <span className="validation__pdf--text">
                          Student_Receipt.pdf
                        </span>
                        <div className="validation__pdf--subInfo">
                          <span className="validation__pdf--subText">
                            {formatDate(Date.now())}
                          </span>
                          <span className="validation__pdf--subInfoText">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.7954 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z"
                                fill="#1FC16B"
                              />
                            </svg>
                            completed
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="collect-fee__subheading">net to be collected</div>
              <div className="collect-fee__due-info">
                <div className="collect-fee__due-info--div">
                  <span className="collect-fee__total-due">Total Due {filterTerm!==null && termFeeData.length!==1 ?termFeeData.length >=Number(filterTerm)?`of Term ${filterTerm}`:'':''}</span>
                  <span className="collect-fee__price">
                  {filterTerm===null && formatPriceWithExponential(
                termFeeData.reduce(
                  (acc, data) =>
                    acc +
                    data.term_fee -
                    data.term_concession -
                    data.amount_paid,
                  0
                ) 
              )}
                    {filterTerm!==null && formatPriceWithExponential(
                      Number(item.total_term_fee) -
                        Number(item.total_term_concession) -
                        Number(item.total_amount_paid)
                    )}
                  </span>
                </div>
                <span className="collect-fee__terms-paid">
                  <div
                    className="circular__progress"
                    style={{
                      background: `conic-gradient(var(--green-color) ${progressDegree}deg, var(--gray-color-200) ${progressDegree}deg)`,
                    }}
                  ></div>
                  <span className="collect-fee__terms-paid--text">
                    {item.terms_completed} of {item.terms} terms paid
                  </span>
                </span>
              </div>
              {isOpen && (
                <CollectFeeTable
                  updateTableData={updateTableData}
                  data={termFeeData}
                  user_id={item.user_id}
                  fee_structure_id={item.fee_structure_id}
                  is_second_page={isSecondPage}
                  setIsSecondPage={setIsSecondPage}
                  setReceiptId={setReceiptId}
                  updatedFeeData={updatedFeeData}
                  setPaymentMethod={setPaymentMethod}
                />
              )}
            </React.Fragment>
          )}
        </section>
        {isSecondPage ? (
          <footer className="collect-fee__footer">
            <div className="collect-fee__footer-btn--container">
              <button
                className="collect-fee__footer--btn collect-fee__footer--btn--secondary"
                disabled={loading}
                onClick={() => handlePrintPDF(receiptData)}
              >
                {loading ? "Generating PDF..." : "Print PDF"}
              </button>
              <button
                className="collect-fee__footer--btn"
                onClick={() => {
                  handlerefresh();
                  setIsSecondPage(false);
                  handlers.toggle();
                }}
              >
                Close
              </button>
            </div>
          </footer>
        ) : null}
      </Sheet>
    </div>
  );
};

CollectFee.propTypes = {
  item: PropTypes.object,
};

export default CollectFee;
