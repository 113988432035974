import React from "react";
import "./StaffGeneralInfo.css";
import StatusButton from "../../FeeManagementModule/FeeStructureModule/components/StatusButton";
import { findnull } from "../../StudentDirectoryModule/StudentGeneralInfo";
import { formatDate } from "../../FeeManagementModule/FeeStructureModule/hooks";
// import { StudentInfoEditSilder } from "../../../Components/StudentInfoEditSilder";
// import { PersonalInfoEditSilder } from "./Components/PersonalInfoEditSlider";
// import { GuardianInfoEditSlider } from "./Components/GuardianInfoEditSlider";

export const StaffGeneralInfo = ({staffdata}) => {
  //   const [studentinfoedit, setstudentinfoedit] = useState(false);
  //   const [personalinfoedit, setpersonalinfoedit] = useState(false);
  //   const [guardianinfoedit, setguardianinfoedit] = useState(false);
  return (
    <div className="StudentGeneralInfodiv">
      <div className="StudentGeneralInfodivCard">
        <div className="header">
          General info
          {/* <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setstudentinfoedit(true);
            }}
          /> */}
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>FIRST NAME</span>
              {staffdata.first_name}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>LAST NAME</span>
              {findnull(staffdata.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>AGE</span>
              23 years
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>CONTACT NUMBER</span>
              {findnull(staffdata.phone_number)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMAIL ID</span>
              {findnull(staffdata.email)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>JOINING DATE</span>
              {formatDate(staffdata.joining_date)}
            </div>
          </div>
        </div>
        <div className="header">
          Personal info
          {/* <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setpersonalinfoedit(true);
            }}
          /> */}
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>DATE OF BIRTH</span>
              {formatDate(staffdata.dob)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>GENDER</span>
              {findnull(staffdata.gender)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>BLOOD GROUP</span>
              {findnull(staffdata.blood_group)}
            </div>
            <div className="StudentGeneralInfodivCardItembody" style={{whiteSpace:'normal',overflowWrap:'break-word',paddingRight:'0.3rem'}}>
              <span>HEALTH CONDITIONS</span>
              {findnull(staffdata.health_conditions)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <div>
                <span>NAME OF EMERGENCY</span>
                <span> CONTACT</span>
              </div>
              <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}} >

              {findnull(staffdata.name_of_emergency_contact)} <span className="cylinder-shape" style={{display:'flex',alignItems:'center'}}>{findnull(staffdata.relation_of_emergency_contact)} </span>
              </div>
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>PHONE NUMBER</span>
              {findnull(staffdata.emergency_contact_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>ADDRESS</span>
              {findnull(staffdata.address)}
            </div>
          </div>
        </div>
        <div className="header">
          Professional info
          {/* <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setguardianinfoedit(true);
            }}
          /> */}
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>EXPERIENCE</span> {findnull(staffdata.years_of_experience)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>SUBJECT</span>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <span className="cylinder-shape"> {findnull(staffdata.primary_subject)}</span>
                
              </div>
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>HIGHEST QUALIFICATION</span>
              {findnull(staffdata.highest_education)}
              
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMPLOYEMENT TYPE</span>
              {findnull(staffdata.employment_type)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>BACKGROUND CHECK</span>
              <StatusButton status={findnull(staffdata.background_check)==="-"?"NotActive":"Active"} />
            </div>
          </div>
        </div>
        {/* <div className="header">
          Current Teaching details
        <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setguardianinfoedit(true);
            }}
          /> 
        </div> */}
        {/* <div
          className="StudentGeneralInfodivCardItem"
          style={{ borderBottom: "0" }}
        >
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>EXPERIENCE</span>2 Years 6 Months
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>SUBJECT</span>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <span className="cylinder-shape"> {"MATHS"}</span>
                <span className="cylinder-shape"> {"CHEMISTRY"}</span>
              </div>
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>HIGHEST QUALIFICATION</span>
              M.Tech
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMPLOYEMENT TYPE</span>
              Full-Time
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>BACKGROUND CHECK</span>
              <StatusButton status="Active" />
            </div>
          </div>
        </div> */}
      </div>
      {/* {studentinfoedit && (
        <StudentInfoEditSilder sliderstate={setstudentinfoedit} />
      )}
      {personalinfoedit && (
        <PersonalInfoEditSilder sliderstate={setpersonalinfoedit} />
      )}
      {guardianinfoedit && (
        <GuardianInfoEditSlider sliderstate={setguardianinfoedit} />
      )} */}
    </div>
  );
};
