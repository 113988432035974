import React, { useEffect, useState } from "react";
import "./EditDetailsSlider.css";
// import { SlArrowDown } from "react-icons/sl";
import DateSelector from "./DateDropDown";
import { CustomDropdownWithCustomStyle } from "./CustomDropdownWithCustomStyle";
import { editpersonalinfo } from "../../../api/api";

export const PersonalInfoEditSilder = ({ sliderstate, data }) => {
  // const categories = ["asa", "asa", "ad", "asds"];
  // const [checkedItems1, setcheckedItems1] = useState(
  //   new Array(categories.length + 1).fill(false)
  // );
  //eslint-disable-next-line
  const [loading, setloading] = useState(true);
  const [editdata, editsetdata] = useState([]);
  useEffect(() => {
    try {
      setloading(true);
      editsetdata(data);
      // setenrollstatus(data.enrolment_status)
    } catch (error) {
      setloading(false);
    }
  }, [data]);
  const handledatachange = async (key, value) => {
    let temp = { ...editdata };

    console.log(key, value);
    temp[key] = value;

    console.log(temp);
    await editsetdata(temp);
  };
  const handlesavechanges = async () => {
    console.log(editdata);
    let tempdata = {
      id: editdata.id,
      dob: editdata.dob,
      gender: editdata.gender,
      aadhaar_number: editdata.aadhaar_number,
      blood_group: editdata.blood_group,
      health_conditions: editdata.health_conditions,
      remarks: editdata.remarks,
      zodiac_sign:editdata.zodiac_sign,
      //  enrolment_status: editdata.enrolment_status,
    };
    console.log(tempdata);
     await editpersonalinfo(tempdata);
    // navigate("/student/transactions//general");
    sliderstate(false);
  };
  const findnull = (value) => {
    if (value === null || value === undefined || value === "") {
      return "-";
    }
    return value;
  };
  return (
    <div className="EditSlider">
      <div className="EditSliderdiv">
        <div className="EditSliderdivheader">
          Edit Personal Info
          <svg
            onClick={() => {
              sliderstate(false);
            }}
            style={{ cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
              fill="#525866"
            ></path>
          </svg>
        </div>
        <div
          className="StudentInfoEditSilderdivbody"
          style={{ paddingBottom: "5rem" }}
        >
          <div className="Details">
            <label style={{ display: "flex" }}>
              Date Of Birth
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <DateSelector
              handledatachange={handledatachange}
              field={"dob"}
              width={"32.5rem"}
              value={editdata.dob}
            />
          </div>

          <div className="Details">
            <label style={{ display: "flex" }}>
              Gender
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <CustomDropdownWithCustomStyle
              defaultText={findnull(editdata.gender)==="-"?"Select Gender":editdata.gender}
              // icon={<FilterIcon />}
              handledatachange={handledatachange}
              items={["male", "female"]}
              width={"32.5rem"}
              dropdownName={"gender"}
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Aadhar Number
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              value={editdata.aadhaar_number}
              style={{ width: "32.5rem" }}
              onChange={(event) => {
                handledatachange("aadhaar_number", event.target.value);
              }}
              placeholder="123456789"
            />
          </div>

          <div className="Details">
            <label style={{ display: "flex" }}>
              Blood Group
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <CustomDropdownWithCustomStyle
              defaultText={
                editdata.blood_group
                  ? editdata.blood_group
                  : "Select Blood Group"
              }
              // icon={<FilterIcon />}
              handledatachange={handledatachange}
              width={"32.5rem"}
              dropdownwidth="32.5rem"
              scrollenable={true}
              items={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
              dropdownName={"blood_group"}
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Sun Sign
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <CustomDropdownWithCustomStyle
              defaultText={
                editdata.zodiac_sign
                  ? editdata.zodiac_sign
                  : "Select Sun Sign"
              }
              // icon={<FilterIcon />}
              handledatachange={handledatachange}
              width={"32.5rem"}
              dropdownwidth="32.5rem"
              scrollenable={true}
              items={[
                "Aries",
                "Taurus",
                "Gemini",
                "Cancer",
                "Leo",
                "Virgo",
                "Libra",
                "Scorpio",
                "Sagittarius",
                "Capricorn",
                "Aquarius",
                "Pisces"
            ]}
              dropdownName={"zodiac_sign"}
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Health Conditions
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </label>
            <input
              type="text"
              className="inputbox"
              value={editdata.health_conditions}
              style={{ width: "32.5rem" }}
              onChange={(event) => {
                handledatachange("health_conditions", event.target.value);
              }}
              placeholder="Current Health conditions"
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Remarks
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "32.5rem" }}
              placeholder="123456789"
              value={editdata.remarks}
              onChange={(event) => {
                handledatachange("remarks", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="footerbutton">
          <div
            className="cancel"
            //   onClick={() => {
            //     buttonstate(false);
            //   }}
          >
            Cancel
          </div>
          <div
            className="Proceed"
            onClick={() => {
              console.log("hello continuing");
              handlesavechanges();
            }}
          >
            Save Changes
          </div>
        </div>
      </div>
    </div>
  );
};
